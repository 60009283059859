import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Link } from "@carbon/react";
import { Download } from "@carbon/icons-react";

const PreviewImage = ({ link, fileName, onClose }) => {
  const containerRef = useRef();
  useOnClickOutside(containerRef, onClose);

  return (
    <div className="py-80 h-screen flex justify-center items-center">
      <div
        ref={containerRef}
        className="flex flex-col w-1/2 max-w-920 h-auto max-h-screen"
      >
        <div className="flex-1 w-full overflow-y-auto">
          <img src={link} alt="" className="w-full h-full object-cover" />
        </div>

        <div className="flex items-center justify-between gap-8">
          {fileName && <p className="text-14 mb-4 line-clamp-1">{fileName}</p>}
          <Link
            download
            href={link}
            renderIcon={() => <Download aria-label="Arrow Right" />}
          >
            Download
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PreviewImage;
