import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";
import { addMinutes } from "utils";
import { useShallow, useTaskCacheStore } from "stores";
import { Button } from "@carbon/react";
import { toast } from "react-toastify";

const EndChat = () => {
  const taskId = useTaskCacheStore(useShallow((state) => state.taskId));
  const chatClosedAt = useTaskCacheStore(useShallow((state) => state.chatClosedAt));
  const setChatClosedAt = useTaskCacheStore(useShallow((state) => state.setChatClosedAt));
  const closeChat = useTaskCacheStore(useShallow((state) => state.closeChat));

  const [countDown, setCountDown] = useState(0);
  const timerId = useRef();

  const { mutate, isPending } = useMutation({
    mutationFn: () => api.post({ path: `tasks/${taskId}/close`, values: null }),
    onSuccess: () => {
      setChatClosedAt(new Date().toISOString());
    },
    onError: (error) => toast.error(error),
  });

  useEffect(() => {
    if (chatClosedAt) {
      let startTime = new Date(chatClosedAt);
      const dateTimeAfterFiveMinutes = addMinutes(startTime, 5);
      const diffTime =
        dateTimeAfterFiveMinutes.getTime() - new Date().getTime();

      let seconds = Math.floor(diffTime / 1000);
      setCountDown(seconds);

      timerId.current = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
      clearInterval(timerId.current);
    }

    return () => clearInterval(timerId.current);
  }, [chatClosedAt]);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(0);
      closeChat();
    }
  }, [countDown]);

  const displaySeconds = String(countDown % 60).padStart(2, "0");
  const displayMinutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  return (
    <>
      {chatClosedAt ? (
        <span className="px-12 py-6 border border-solid border-th-button-disabled">
          Chat closes in ({displayMinutes}: {displaySeconds})
        </span>
      ) : null}
      {!chatClosedAt ? (
        <Button disabled={isPending} size="sm" kind="tertiary" onClick={mutate}>
          End chat
        </Button>
      ) : null}
    </>
  );
};

export default EndChat;

// import { Button } from "@carbon/react";
// import { useEffect, useRef, useState } from "react";
// import { useShallow, useTaskCacheStore } from "stores";
// import { addMinutes } from "utils";

// const EndChat = () => {
//   const chatClosedAt = useTaskCacheStore(
//     useShallow((state) => state.chatClosedAt)
//   );
//   const setChatClosedAt = useTaskCacheStore(
//     useShallow((state) => state.setChatClosedAt)
//   );

//   const [countDown, setCountDown] = useState(null);
//   const timerId = useRef();

//   useEffect(() => {
//     if (chatClosedAt) {
//       const countDownDate = new Date(chatClosedAt).getTime();
//       timerId.current = setInterval(() => {
//         setCountDown(countDownDate - new Date().getTime());
//       }, 1000);
//     } else {
//       clearInterval(timerId.current);
//     }

//     return () => clearInterval(timerId.current);
//   }, [chatClosedAt]);

//   useEffect(() => {
//     if (countDown < 0) {
//       setCountDown(null);
//       setChatClosedAt("");
//     }
//   }, [countDown]);

//   const handleClick = () => {
//     const dateTimeAfterFiveMinutes = addMinutes(new Date(), 0.1);
//     setChatClosedAt(dateTimeAfterFiveMinutes);
//   };

//   const displaySeconds = Math.floor((countDown / 1000) % 60);
//   const displayMinutes = Math.floor((countDown / 1000 / 60) % 60);

//   return (
//     <>
//       {timerId.current ? (
//         <span className="px-12 py-6 border border-solid border-th-button-disabled">
//           Chat closes in ({displayMinutes}:{displaySeconds})
//         </span>
//       ) : null}
//       {!timerId.current ? (
//         <Button size="sm" kind="tertiary" onClick={handleClick}>
//           End chat
//         </Button>
//       ) : null}
//     </>
//   );
// };

// export default EndChat;
