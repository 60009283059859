import { Button } from "@carbon/react";
import { Renew } from "@carbon/icons-react";
import astronaut from "assets/images/astronaut.jpg";

const WebSocketFailureModal = () => {
  return (
    <div className="py-80 px-16 max-w-1320 m-auto h-screen flex justify-between items-center gap-64">
      <div className="flex-1">
        <h3 className="mb-32 text-72 font-semibold text-th-text-primary">
          Houston, we have a problem!
        </h3>
        <p className="text-24 text-th-text-secondary">
          You have encountered an error.
        </p>
        <p className="mb-32 text-24 text-th-text-secondary">
          Our team has been notified. We apologize for any inconvenience.
        </p>
        <Button onClick={() => window.location.reload()} renderIcon={Renew}>
          Refresh page
        </Button>
      </div>
      <div className="shrink-0 flex-1 max-w-600 h-auto">
        <img src={astronaut} alt="" className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default WebSocketFailureModal;
