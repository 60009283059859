import { useLocation, useNavigate } from "react-router-dom";
import usePermissions from "hooks/usePermissions";
import { cn } from "utils";
import { useSideNavExpand } from "stores";

import {
  SideNavItems,
  SideNavLink,
  SideNavDivider,
  SideNavMenuItem,
  SideNavMenu,
} from "@carbon/react";
import NavRoomList from "./NavRoomList";

import {
  Task,
  Events,
  SettingsServices,
  ChevronLeft,
} from "@carbon/icons-react";

const LeftSidebar = ({ className }) => {
  const { hasPermission } = usePermissions();
  const { isExpanded, setIsExpanded } = useSideNavExpand();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const navigateTo = (path) => () => {
    if (!isExpanded) setIsExpanded();
    navigate(path);
  };

  return (
    <div
      className={cn(
        "fixed top-0 left-0 h-full pt-48 bg-th-layer-01 z-100 overflow-y-auto",
        isExpanded ? "w-300" : "w-48",
        className
      )}
    >
      <SideNavItems>
        <SideNavLink
          className="cursor-pointer"
          onClick={setIsExpanded}
          renderIcon={() => (
            <div
              className={cn(
                "w-18 h-18 flex justify-center items-center shrink-0 bg-th-layer-accent-01 rounded-full transition-transform",
                isExpanded && "rotate-180"
              )}
            >
              <ChevronLeft className="fill-th-icon-primary" />
            </div>
          )}
        >
          Collapse
        </SideNavLink>

        <SideNavDivider />

        <SideNavMenu
          isActive={pathname.includes("/rooms")}
          renderIcon={Task}
          title="Rooms"
        >
          <NavRoomList navigateTo={navigateTo} />
        </SideNavMenu>

        {hasPermission("clientsNav.read") && (
          <>
            <SideNavDivider />
            <SideNavMenu
              isActive={pathname.includes("/dashboard/clients")}
              renderIcon={Events}
              title="Clients"
            >
              <SideNavMenuItem
                isActive={pathname.includes(
                  "/dashboard/clients/motor-carriers"
                )}
                className="cursor-pointer"
                onClick={navigateTo("/dashboard/clients/motor-carriers")}
              >
                Motor carriers
              </SideNavMenuItem>
              <SideNavMenuItem
                isActive={pathname.includes("/dashboard/clients/drivers")}
                className="cursor-pointer"
                onClick={navigateTo("/dashboard/clients/drivers")}
              >
                Drivers
              </SideNavMenuItem>
            </SideNavMenu>
          </>
        )}

        {hasPermission("adminSettingNav.read") && (
          <>
            <SideNavDivider />
            <SideNavLink
              isActive={pathname.includes("/dashboard/admin-settings")}
              className="cursor-pointer"
              onClick={navigateTo("/dashboard/admin-settings")}
              renderIcon={SettingsServices}
            >
              Admin settings
            </SideNavLink>{" "}
          </>
        )}
      </SideNavItems>
    </div>
  );
};

export default LeftSidebar;
