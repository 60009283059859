import { useEffect, createContext, useContext, useRef } from 'react';
import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from '@microsoft/signalr';

import { usePopupStore, useRooms, useShallow, useTaskCacheStore } from 'stores';

import { API_WS_ROOM_HUB, totalTasksByIdMap } from 'cnst';
import { playSoundNotification } from 'helpers';
import { api } from 'services';

// const getAuthHeaders = (accessToken) => {
//   return {
//     Authorization: `Bearer ${accessToken}`,
//   };
// };

// class CustomHttpClient extends DefaultHttpClient {
//   constructor() {
//     super(console);
//   }
//   async send(request) {
//     let accessToken = await api.getAccessToken();
//     let authHeaders = getAuthHeaders(accessToken);
//     request.headers = { ...request.headers, ...authHeaders };
//     console.log("connecting");

//     try {
//       const response = await super.send(request);
//       return response;
//     } catch (error) {
//       if (error instanceof HttpError) {
//         if (error.statusCode === 401) {
//           await api.updateTokens();
//           accessToken = await api.getAccessToken();
//           authHeaders = getAuthHeaders(accessToken);
//           request.headers = { ...request.headers, ...authHeaders };
//         }
//       } else {
//         throw error;
//       }
//     }
//     console.log("reconnect");
//     return await super.send(request);
//   }
// }

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const connectionRef = useRef();
  const setRoomNames = useRooms(useShallow((store) => store.setRoomNames));
  const setTotalByTasks = useRooms(useShallow((store) => store.setTotalByTasks));
  const setCardsByIds = useRooms(useShallow((store) => store.setCardsByIds));
  const setTotalByIds = useRooms(useShallow((store) => store.setTotalByIds));
  const removeTask = useRooms(useShallow((store) => store.removeTask));
  const moveTask = useRooms(useShallow((store) => store.moveTask));
  const addNewMessage = useTaskCacheStore(useShallow((store) => store.addNewMessage));
  const addNewHistory = useTaskCacheStore(useShallow((store) => store.addNewHistory));
  const updateMessageNotification = useRooms(useShallow((store) => store.updateMessageNotification));
  const handleOpenPopup = usePopupStore(useShallow((store) => store.setOpen));
  // const handleClosePopup = usePopupStore(useShallow((store) => store.setClose));

  useEffect(() => {
    connectionRef.current = new HubConnectionBuilder()
      .withUrl(API_WS_ROOM_HUB, {
        // skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: async () => await api.getAccessToken(),
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect([5000, 15000, 30000, 45000, 60000, null])
      .build();

    connectionRef.current.on('setRooms', (data) => {
      console.group('setRooms');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      const parsedData = JSON.parse(data);
      let roomNames = {};
      if (typeof parsedData === 'object') {
        parsedData?.reduce((accumulator, currentValue) => {
          const { id, name } = currentValue;
          let totals = {};

          for (const [key, value] of Object.entries(totalTasksByIdMap)) {
            if (currentValue.hasOwnProperty(value)) {
              totals[key] = currentValue[value];
            }
          }

          setTotalByTasks({
            roomId: id,
            payload: totals,
          });
          accumulator[id] = name || '';
          return accumulator;
        }, roomNames);
      }

      setRoomNames(roomNames);
    });
    connectionRef.current.on('taskNotification', (data) => {
      console.group('taskNotification');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();
    });
    connectionRef.current.on('addNewTask', (data) => {
      console.group('addNewTask');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      const parsedData = JSON.parse(data);
      const { roomId, taskCard } = parsedData;
      setCardsByIds({
        roomId: roomId,
        taskColId: 'newTasks',
        payload: taskCard,
      });
      setTotalByIds({ roomId, taskColId: 'newTasks', payload: 1 });
      playSoundNotification();
    });
    connectionRef.current.on('newMessage', (data) => {
      console.group('newMessage');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      const parsedData = JSON.parse(data);
      parsedData.forEach((data) => {
        addNewMessage(data);
        updateMessageNotification(data);
      });
    });
    connectionRef.current.on('newHistory', (data) => {
      console.group('newHistory');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      const parsedData = JSON.parse(data);
      parsedData.forEach((data) => {
        addNewHistory(data);
      });
    });
    connectionRef.current.on('taskStatusChanged', (data) => {
      console.group('taskStatusChanged');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      moveTask(JSON.parse(data));
    });
    connectionRef.current.on('decrementNewTasksCounter', (data) => {
      console.group('decrementNewTasksCounter');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      const { roomId, amount } = JSON.parse(data);
      setTotalByIds({ roomId, taskColId: 'newTasks', payload: -amount });
    });
    connectionRef.current.on('removeTaskFromUser', (data) => {
      console.group('removeTaskFromUser');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.log('data -', JSON.parse(data));
      console.groupEnd();

      removeTask(JSON.parse(data));
    });

    connectionRef.current.on('logout', () => {
      console.group('logout');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.groupEnd();
      localStorage.removeItem('tokens');
      window.location.reload();
    });

    connectionRef.current.onclose(() => {
      console.group('close ws');
      console.log('connectionId -', connectionRef.current?.connectionId);
      console.groupEnd();
      handleOpenPopup({ key: 'wsDeath' });
    });

    connectionRef.current.start().then(() => {
    });

    return () => {
      // console.log("ws stop");
      // handleClosePopup();
      // connectionRef.current.stop();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{}}>{children}</WebSocketContext.Provider>
  );
};

export const useWebsocket = () => useContext(WebSocketContext);
