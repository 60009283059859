import { Fragment, createElement } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'services';

import { cn, createObjectBaseMatchVal } from 'utils';
import withFetchFormData from 'hoc/withFetchFormData';
import { toast } from 'react-toastify';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEmployeeSchema } from 'schema';

import SidebarHeading from 'components/SidebarHeading';
import FormGroupBtn from 'components/FormGroupBtn';

import { employeeForm } from './formData';
import { roleAdminOption } from 'data/options';

const getRoomOptions = (data) => {
  const [roomOptions] = data || [];
  const options = roomOptions?.entities.map((room) => ({
    value: room.id,
    text: room.name,
  }));

  return { key: 'roomIds', options: options || [] };
};

function RenderRow ({ control, input, errors, watchRole, userRole }) {
  const { type, Component, name, inputProps, withValue, change } = input;
  let render = null;
  switch (type) {
    case 'input':
      render = (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const { ref, value, onChange, ...restField } = field;
            const errorMessage = errors?.[name]?.message;

            return createElement(Component, {
              id: name,
              light: true,
              invalid: Boolean(errorMessage),
              invalidText: errorMessage,
              ...inputProps,
              ...restField,
              ...(withValue ? withValue(value) : { value }),
              ...(change ? change(onChange) : { onChange }),
              ...(name === 'roomIds' && { disabled: watchRole === 'admin' }),
              ...(name === 'role' && userRole === 'developer' ? { options: [roleAdminOption, ...inputProps.options] } : {})
            });
          }}
        />
      );
      break;
    case 'groupName':
      render = (
        <span className={inputProps.className}>
          {inputProps.text}
        </span>
      );
      break;
    default:
  }

  return <Fragment key={name}>{render}</Fragment>;
}

const UpdateEmployee = ({
  userRole,
  operation,
  formValues,
  fetchedData,
  method,
  path,
  queryKey,
  heading,
  className,
  onClose,
  primaryButtonText,
}) => {
  const queryClient = useQueryClient();
  const { defaultValues, formInputs } = employeeForm;

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api[method]({ path, values }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit, watch } = useForm({
    mode: 'onChange',
    defaultValues: createObjectBaseMatchVal(defaultValues, formValues),
    resolver: yupResolver(getEmployeeSchema(operation)),
  });
  const { errors } = formState;
  const watchRole = watch('role');

  const handleSubmitForm = handleSubmit((values) => {
    const formData = new FormData();

    for (const key in values) {
      const value = values[key];
      if (key === 'roomIds') {
        value?.forEach((item) => {
          formData.append('roomIds[]', item.value);
        });
      } else {
        formData.append(key, value);
      }
    }

    mutate(formData);
  });

  if (fetchedData.options.length) {
    formInputs[fetchedData.key].inputProps.items = fetchedData.options;
  }

  return (
    <>
      <SidebarHeading onClose={onClose}>{heading}</SidebarHeading>

      <form className={cn('', className)} onSubmit={handleSubmitForm}>
        <div className="px-16 grid grid-cols-12 gap-16">
          {Object.values(formInputs).map((input, index) => (
            <RenderRow
              key={index} control={control}
              input={input}
              errors={errors}
              watchRole={watchRole}
              userRole={userRole}
            />
          ))}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText={primaryButtonText}
          onClose={onClose}
          isPending={isPending}
        />
      </form>
    </>
  );
};

export default withFetchFormData(UpdateEmployee, {
  paths: ['/rooms'],
  transformData: getRoomOptions,
});
