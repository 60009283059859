import { CloseLarge } from "@carbon/icons-react";
import { cn } from "utils";

const SidebarHeading = ({ children, className, onClose = () => {} }) => {
  return (
    <h4
      className={cn(
        "mb-16 px-16 pt-16 flex justify-between items-center",
        className
      )}
    >
      {children}
      <CloseLarge
        className="shrink-0 w-20 h-20 cursor-pointer"
        onClick={onClose}
      />
    </h4>
  );
};

export default SidebarHeading;
