import { forwardRef, useEffect, useRef } from 'react';
import { usePopupStore, useShallow, useTaskCacheStore, useUser } from 'stores';
import { useMutation } from '@tanstack/react-query';
import { api } from 'services';
import { messageCardStyleMap } from 'cnst';

import { cn, getInsertedImageIntoDoc } from 'utils';
import useScrollToBottomOnMessageChange from 'hooks/useScrollToBottomOnMessageChange';

import { Button, Loading } from '@carbon/react';
import TextArea from 'components/TextArea';
import FileUpload from 'components/FileUpload';
import TextMessage from 'components/Message/TextMessage';
import DocumentMessage from 'components/Message/DocumentMessage';
import EndChat from 'components/EndChat';

import { Attachment, SendFilled } from '@carbon/react/icons';

const LiveChatTab = ({ className }) => {
  const data = useTaskCacheStore(useShallow((state) => state.liveChat));
  const { anchorMessageRef } = useScrollToBottomOnMessageChange(data.messages);

  return (
    <div className={cn('flex justify-between flex-col', className)}>
      <Header />
      <div className="flex-1 flex flex-col overflow-auto">
        <MessageList
          ref={anchorMessageRef}
          messages={data.messages}
          className="flex-1"
        />
      </div>
      <ShowMessageInput taskId={data.id} />
    </div>
  );
};

function Header () {
  const user = useUser(useShallow((state) => state.user));
  const assignees = useTaskCacheStore(useShallow((state) => state.assignees));
  const isChatOpened = useTaskCacheStore(
    useShallow((state) => state.isChatOpened)
  );

  const userIsAssigned = assignees.some((assignee) => assignee.id === user.id);

  const showEndChat = userIsAssigned && isChatOpened;

  return (
    // border-0 border-b border-solid border-th-layer-03
    <div className="sticky top-0 h-48 px-24 flex justify-between items-center text-14 font-semibold bg-th-layer-02">
      Chat
      {showEndChat && <EndChat />}
    </div>
  );
}

export const MessageList = forwardRef(({ className, messages }, ref) => {
  return (
    <div className={cn('flex flex-col justify-end px-24 pt-12', className)}>
      {messages.map((message, index) => {
        const isEqualType = message.from === messages[index + 1]?.from;
        const spacing = isEqualType ? 'mb-4' : 'mb-20';
        return (
          <ChatMessage key={message.id} message={message} className={spacing} />
        );
      })}
      <div ref={ref} />
    </div>
  );
});

export function ChatMessage ({ message, className }) {
  const styles = messageCardStyleMap[message.from] || {};

  switch (message?.type) {
    case 'text':
      return (
        <TextMessage className={className} styles={styles} data={message} />
      );
    case 'log':
      return (
        <TextMessage className={className} styles={styles} data={message} />
      );
    case 'document':
      return (
        <DocumentMessage className={className} styles={styles} data={message} />
      );
    default:
      return null;
  }
}

const withShowInput = (WrappedComponent) => (props) => {
  const user = useUser(useShallow((state) => state.user));
  const assignees = useTaskCacheStore(useShallow((state) => state.assignees));
  const isChatOpened = useTaskCacheStore(
    useShallow((state) => state.isChatOpened)
  );

  const userIsAssigned = assignees.some((assignee) => assignee.id === user.id);

  const showComponent = userIsAssigned && isChatOpened;
  if (showComponent) return <WrappedComponent {...props} />;

  return null;
};

export function MessageInput ({ taskId, className }) {
  const textareaRef = useRef();
  const handleOpen = usePopupStore(useShallow((store) => store.setOpen));
  const { mutate, isPending } = useMutation({
    mutationFn: (values) => {
      api.post({ path: `tasks/${taskId}/messages`, values });
    },
    onSuccess: () => {textareaRef.current.value = '';},
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    function handlePaste (evt) {
      // evt.preventDefault();

      const files = getInsertedImageIntoDoc(evt?.clipboardData?.files);
      if (files) {
        openFilesSendModal(files);
      }
    }

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  function openFilesSendModal (files) {
    handleOpen({
      key: 'sendingFiles',
      props: {
        path: `tasks/${taskId}/messages`,
        files,
        taskId,
      },
    });
  }

  function handleKeyUp (evt) {
    const textareaValue = textareaRef.current.value.trim();
    if (evt.keyCode === 13 && !evt.shiftKey && Boolean(textareaValue)) {
      evt.preventDefault();
      handleSendMessage();
    }
  }

  function handleSendMessage () {
    const textareaValue = textareaRef.current.value.trim();
    if (!textareaValue) return false;

    handleMutate(textareaValue);
  }

  function handleMutate (text) {
    const formData = new FormData();
    formData.append('type', 'text');
    formData.append('content', text);
    formData.append('taskId', taskId);
    mutate(formData);
  }

  return (
    <div
      className={cn(
        'w-full flex gap-4 border-0 border-t border-solid border-th-border-subtle-02',
        className
      )}
    >
      <FileUpload containerClass="self-end" onFilesAccepted={openFilesSendModal}>
        <Button disabled={isPending} kind="ghost">
          <Attachment />
        </Button>
      </FileUpload>

      <TextArea
        ref={textareaRef}
        readOnly={isPending}
        onKeyDown={handleKeyUp}
        className="flex-1"
        placeholder="Type something..."
      />

      <Button
        disabled={isPending}
        kind="ghost"
        className="self-end"
        onClick={handleSendMessage}
      >
        {isPending ? <Loading small withOverlay={false} /> : <SendFilled />}
      </Button>
    </div>
  );
}

const ShowMessageInput = withShowInput(MessageInput);

export default LiveChatTab;
