import { forwardRef } from "react";
import { cn, getInitials } from "utils";

const Avatar = ({ className, name, photo }, ref) => {
  const avatar = photo || getInitials(name || "");
  return (
    <div
      ref={ref}
      className={cn(
        "w-36 h-36 flex justify-center items-center text-12 uppercase text-th-text-primary bg-th-tag-background-purple rounded-full overflow-hidden",
        className
      )}
    >
      {avatar}
    </div>
  );
};

export default forwardRef(Avatar);
