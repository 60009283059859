import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { toast } from 'react-toastify';

const KEY_CODES = {
  'DOWN': 40,
  'UP': 38,
  'ENTER': 13,
};

export const useAutoSuggest = ({
  value = '',
  delay = 750,
  minLength = 1,
  fancyMode = true,
  fancyModeValidate = () => {},
  source = async () => [],
  onChange = () => {},
}) => {
  const containerRef = useRef();
  const inputRef = useRef();
  const listRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const optionHeight = listRef?.current?.children[0]?.clientHeight;
  const keyOperation = {
    [KEY_CODES.DOWN]: scrollDown,
    [KEY_CODES.UP]: scrollUp,
    [KEY_CODES.ENTER]: () => handleSelectOption(selectedIndex),
  };

  useOnClickOutside(containerRef, handleCloseMenu);
  const delayInvoke = useCallback(debounce((cb) => cb(), delay), []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  async function getSuggestions (searchTerm) {
    if (searchTerm.length >= minLength) {
      let sameSearch = true;
      try {
        let data = await source(searchTerm);
        sameSearch = inputRef.current.value === searchTerm;
        sameSearch && setSuggestions(data ?? []);
      } catch (error) {
        toast.error(error);
        setIsOpenMenu(false);
      } finally {
        sameSearch && setIsLoading(false);
      }
    }
  }

  function handleInputChange (evt) {
    let searchTerm = evt.target.value;
    setInputValue(searchTerm);

    if (!searchTerm) {
      handleClearInput();
      return;
    }

    if (fancyMode && fancyModeValidate(searchTerm)) {
      if (isOpenMenu) handleCloseMenu();
      if (isLoading) setIsLoading(false);
      return;
    }

    if (!isOpenMenu) handleOpenMenu();
    if (!isLoading) setIsLoading(true);
    delayInvoke(() => getSuggestions(searchTerm));
  }

  function handleKeyUp (evt) {
    const inputValue = evt.target.value;

    if (!fancyMode || (fancyMode && !fancyModeValidate(inputValue))) {
      if (keyOperation[evt.keyCode]) {
        keyOperation[evt.keyCode]();
      } else {
        setSelectedIndex(-1);
      }
    }

    if (fancyMode && fancyModeValidate(inputValue) && evt.keyCode === 13) {
      onChange({ text: inputValue, value: inputValue });
    }
  }

  function scrollUp () {
    if (typeof listRef.current?.scrollTop !== 'undefined' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
      listRef.current.scrollTop -= optionHeight;
    }
  }

  function scrollDown () {
    if (typeof listRef.current?.scrollTop !== 'undefined' && selectedIndex < suggestions.length - 1) {
      setSelectedIndex(selectedIndex + 1);
      listRef.current.scrollTop = selectedIndex * optionHeight;
    }
  }

  function handleClearInput () {
    inputRef.current?.focus();
    setInputValue('');
    setSuggestions([]);
    handleCloseMenu();
    setIsLoading(false);
    onChange(null);
    delayInvoke(() => {});
  }

  function handleInputFocus () {
    if (suggestions.length) handleOpenMenu();
  }

  function handleSelectOption (index) {
    if (index > -1) {
      setIsOpenMenu(false);
      setInputValue(suggestions[index]?.text ?? '');
      onChange(suggestions[index]);
      setSuggestions([]);
    }
    setSelectedIndex(-1);
  }

  function handleCloseMenu () {
    setIsOpenMenu(false);
  }

  function handleOpenMenu () {
    setIsOpenMenu(true);
  }

  function getNodeIndex (evt) {
    const nodes = Array.from(listRef.current.children);
    return nodes.indexOf(evt.target.closest('li'));
  };

  return {
    containerRef,
    inputProps: {
      ref: inputRef,
      value: inputValue,
      onChange: handleInputChange,
      onKeyUp: handleKeyUp,
      onFocus: handleInputFocus,
    },
    menuProps: {
      listRef,
      isLoading,
      isOpenMenu,
      suggestions,
      selectedIndex,
      onSelectOption: (evt) => handleSelectOption(getNodeIndex(evt)),
      onMouseOver: (evt) => setSelectedIndex(getNodeIndex(evt)),
    }
  };
};
