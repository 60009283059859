const statusOptions = [
  { value: 'active', text: 'Active' },
  { value: 'inactive', text: 'Inactive' },
];
const roleOptions = [
  { value: 'manager', text: 'Manager' },
  { value: 'checker', text: 'Checker' },
  { value: 'worker', text: 'Worker' },
];
const roleAdminOption = { value: 'admin', text: 'Admin' };

const stateOptions = [
  { value: 'al', text: 'Alabama' },
  { value: 'ak', text: 'Alaska' },
  { value: 'az', text: 'Arizona' },
  { value: 'ar', text: 'Arkansas' },
  { value: 'ca', text: 'California' },
  { value: 'co', text: 'Colorado' },
  { value: 'ct', text: 'Connecticut' },
  { value: 'de', text: 'Delaware' },
  { value: 'fl', text: 'Florida' },
  { value: 'ga', text: 'Georgia' },
  { value: 'gu', text: 'Guam' },
  { value: 'hi', text: 'Hawaii' },
  { value: 'id', text: 'Idaho' },
  { value: 'il', text: 'Illinois' },
  { value: 'in', text: 'Indiana' },
  { value: 'ia', text: 'Iowa' },
  { value: 'ks', text: 'Kansas' },
  { value: 'ky', text: 'Kentucky' },
  { value: 'la', text: 'Louisiana' },
  { value: 'me', text: 'Maine' },
  { value: 'md', text: 'Maryland' },
  { value: 'ma', text: 'Massachusetts' },
  { value: 'mi', text: 'Michigan' },
  { value: 'mn', text: 'Minnesota' },
  { value: 'ms', text: 'Mississippi' },
  { value: 'mo', text: 'Missouri' },
  { value: 'mt', text: 'Montana' },
  { value: 'ne', text: 'Nebraska' },
  { value: 'nv', text: 'Nevada' },
  { value: 'nh', text: 'New-hampshire' },
  { value: 'nj', text: 'New-jersey' },
  { value: 'nm', text: 'New-mexico' },
  { value: 'ny', text: 'New-york' },
  { value: 'nc', text: 'North-carolina' },
  { value: 'nd', text: 'North-dakota' },
  { value: 'oh', text: 'Ohio' },
  { value: 'ok', text: 'Oklahoma' },
  { value: 'or', text: 'Oregon' },
  { value: 'pa', text: 'Pennsylvania' },
  { value: 'ri', text: 'Rhode-island' },
  { value: 'sc', text: 'South-carolina' },
  { value: 'sd', text: 'South-dakota' },
  { value: 'tn', text: 'Tennessee' },
  { value: 'tx', text: 'Texas' },
  { value: 'ut', text: 'Utah' },
  { value: 'vt', text: 'Vermont' },
  { value: 'va', text: 'Virginia' },
  { value: 'wa', text: 'Washington' },
  { value: 'wv', text: 'West-virginia' },
  { value: 'wi', text: 'Wisconsin' },
  { value: 'wy', text: 'Wyoming' },
];

export { statusOptions, roleOptions, roleAdminOption, stateOptions };
