import { statusOptions } from "data/options";
import { TextInput, TextArea } from "@carbon/react";
import CustomSelect from "components/CustomSelect";
import PhoneInputBaseElement from "elements/PhoneInputBaseElement";

const driverForm = {
  defaultValues: {
    companyId: "",
    firstName: "",
    lastName: "",
    cdl: "",
    status: "active",
    hosLink: "",
    truckNumber: "",
    trailerNumber: "",
    phone: "",
    telegramUserName: "",
    email: "",
    notes: "",
  },

  formInputs: {
    firstGroup: {
      type: "groupName",
      name: "grGeneralInfo",
      inputProps: {
        text: "General info",
        className: "col-span-full text-14 font-medium",
      },
    },
    companyId: {
      Component: CustomSelect,
      name: "companyId",
      type: "input",
      inputProps: {
        labelText: "Motor carrier",
        options: [],
        className: "col-start-1 col-end-13",
        defaultOption: {
          value: "",
          text: "Select motor carrier",
          props: { disabled: true },
        },
      },
    },
    firstName: {
      Component: TextInput,
      name: "firstName",
      type: "input",
      inputProps: {
        labelText: "First name",
        placeholder: "Enter first name",
        className: "col-start-1 col-end-7",
      },
    },
    lastName: {
      Component: TextInput,
      name: "lastName",
      type: "input",
      inputProps: {
        labelText: "Last Name",
        placeholder: "Enter last name",
        className: "col-start-7 col-end-13",
      },
    },
    cdl: {
      Component: TextInput,
      name: "cdl",
      type: "input",
      inputProps: {
        labelText: "CDL#",
        placeholder: "Enter CDL#",
        className: "col-start-1 col-end-7",
      },
    },
    status: {
      Component: CustomSelect,
      name: "status",
      type: "input",
      inputProps: {
        labelText: "Status",
        options: statusOptions,
        className: "col-start-7 col-end-13",
        defaultOption: {
          value: "",
          text: "Select status",
        },
      },
    },

    secondGroup: {
      type: "groupName",
      name: "grHosLink",
      inputProps: {
        text: "Hos log link",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    hosLink: {
      Component: TextInput,
      name: "hosLink",
      type: "input",
      inputProps: {
        labelText: null,
        placeholder: "Add hos log link",
        className: "col-span-full",
      },
    },

    thirdGroup: {
      type: "groupName",
      name: "grVehicle",
      inputProps: {
        text: "Vehicle",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    truckNumber: {
      Component: TextInput,
      name: "truckNumber",
      type: "input",
      inputProps: {
        labelText: "Truck#",
        placeholder: "Enter truck#",
        className: "col-start-1 col-end-7",
      },
    },
    trailerNumber: {
      Component: TextInput,
      name: "trailerNumber",
      type: "input",
      inputProps: {
        labelText: "Trailer#",
        placeholder: "Enter trailer#",
        className: "col-start-7 col-end-13",
      },
    },

    fourthGroup: {
      type: "groupName",
      name: "grContactDetails",
      inputProps: {
        text: "Contact details",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    phone: {
      Component: PhoneInputBaseElement,
      name: "phone",
      type: "input",
      inputProps: {
        labelText: "Phone",
        placeholder: "Enter phone",
        className: "col-start-1 col-end-7",
      },
    },
    telegramUserName: {
      Component: TextInput,
      name: "telegramUserName",
      type: "input",
      inputProps: {
        labelText: "Telegram nickname",
        className: "col-start-7 col-end-13",
      },
    },
    email: {
      Component: TextInput,
      name: "email",
      type: "input",
      inputProps: {
        labelText: "Email",
        placeholder: "Enter email",
        className: "col-span-full",
      },
    },

    fifthGroup: {
      type: "groupName",
      name: "grNotes",
      inputProps: {
        text: "Notes",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    notes: {
      Component: TextArea,
      name: "notes",
      type: "input",
      inputProps: {
        placeholder: "Notes",
        className: "col-span-full mb-20",
        labelText: "",
      },
    },
  },
};

export { driverForm };
