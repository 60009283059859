import { Button, Loading } from "@carbon/react";
import { cn } from "utils";

const FormGroupBtn = ({
  containerClass,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  isPending,
}) => {
  return (
    <div className={cn("flex", containerClass)}>
      <Button kind="ghost" className="basis-1/2" onClick={onClose}>
        {secondaryButtonText}
      </Button>
      <Button disabled={isPending} className="basis-1/2" type="submit">
        {primaryButtonText}
        {isPending && <Loading small className="ml-16" withOverlay={false} />}
      </Button>
    </div>
  );
};

export default FormGroupBtn;
