import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { usePopupStore, useShallow } from "stores";
import useLockedBody from "hooks/useLockedbody";
import PreviewImage from "./PreviewImage";
import SendFilesModal from "./SendFilesModal";
import WebSocketFailureModal from "./WebSocketFailureModal";

const Popup = () => {
  const { show, setClose } = usePopupStore(useShallow((store) => store));
  const renderKey = show?.key;
  const renderProps = show?.props;
  const isOpen = Boolean(show);

  useLockedBody(isOpen, document.body);

  if (!show) return null;

  let renderView = null;
  switch (renderKey) {
    case "chatPreviewImage":
      renderView = <PreviewImage {...renderProps} onClose={setClose} />;
      break;
    case "sendingFiles":
      renderView = <SendFilesModal {...renderProps} onClose={setClose} />;
      break;
    case "wsDeath":
      renderView = <WebSocketFailureModal {...renderProps} />;
      break;
    default:
      renderView = null;
  }

  return ReactDOM.createPortal(
    <AnimatePresence mode="wait">
      {isOpen ? (
        <motion.div
          initial={{ filter: "blur(4px)" }}
          animate={{ filter: "blur(0px)" }}
          transition={{ duration: 0.5 }}
          className="fixed top-0 left-0 bottom-0 right-0 w-full bg-th-overlay z-9000"
        >
          {renderView}
        </motion.div>
      ) : null}
    </AnimatePresence>,
    document.body
  );
};

export default Popup;
