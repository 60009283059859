import { extendTailwindMerge } from "tailwind-merge";

const theme = {
  extend: {
    backgroundImage: {
      gradient:
        "linear-gradient(110deg, #0F1011 0%, rgba(0, 0, 0, 0.37) 51.61%, rgba(0, 0, 0, 0.19) 67.26%, rgba(0, 0, 0, 0.00) 100%);",
    },
    fontSize: {
      8: "8px",
      9: "9px",
      10: "10px",
      12: "12px",
      14: "14px",
      16: "16px",
      18: "18px",
      20: "20px",
      22: "22px",
      24: "24px",
      26: "26px",
      28: "28px",
      30: "30px",
      32: "32px",
      34: "34px",
      36: "36px",
      38: "38px",
      40: "40px",
      42: "42px",
      44: "44px",
      46: "46px",
      48: "48px",
      50: "50px",
      72: "72px",
    },
    lineHeight: {
      100: "100%",
      110: "110%",
      115: "115%",
      120: "120%",
      125: "125%",
      130: "130%",
      135: "135%",
      140: "140%",
    },
    letterSpacing: {
      "14-": "-0.14px",
      "16-": "-0.16px",
      "18-": "-0.18px",
      "20-": "-0.2px",
      "24-": "-0.24px",
      "28-": "-0.28px",
      "32-": "-0.32px",
      14: "0.14px",
      16: "0.16px",
      18: "0.18px",
      20: "0.2px",
      24: "0.24px",
      28: "0.28px",
      32: "0.32px",
    },
    colors: {
      black: "#000",
      yellow: "#F7CE51",
      green: "#53AA64",
      vermilion: "#DF5634",
      frostbite: "#E843B8",
      gray: {
        30: "#c6c6c6",
        70: "#525252",
        100: "#161616",
      },
      "th-ai-gradient-end": "var(--cds-ai-gradient-end)",
      "th-ai-gradient-start-01": "var(--cds-ai-gradient-start-01)",
      "th-ai-gradient-start-02": "var(--cds-ai-gradient-start-02)",
      "th-background": "var(--cds-background)",
      "th-background-active": "var(--cds-background-active)",
      "th-background-brand": "var(--cds-background-brand)",
      "th-background-hover": "var(--cds-background-hover)",
      "th-background-inverse": "var(--cds-background-inverse)",
      "th-background-inverse-hover": "var(--cds-background-inverse-hover)",
      "th-background-selected": "var(--cds-background-selected)",
      "th-background-selected-hover": "var(--cds-background-selected-hover)",
      "th-border-disabled": "var(--cds-border-disabled)",
      "th-border-interactive": "var(--cds-border-interactive)",
      "th-border-inverse": "var(--cds-border-inverse)",
      "th-border-strong-01": "var(--cds-border-strong-01)",
      "th-border-strong-02": "var(--cds-border-strong-02)",
      "th-border-strong-03": "var(--cds-border-strong-03)",
      "th-border-subtle-00": "var(--cds-border-subtle-00)",
      "th-border-subtle-01": "var(--cds-border-subtle-01)",
      "th-border-subtle-02": "var(--cds-border-subtle-02)",
      "th-border-subtle-03": "var(--cds-border-subtle-03)",
      "th-border-subtle-selected-01": "var(--cds-border-subtle-selected-01)",
      "th-border-subtle-selected-02": "var(--cds-border-subtle-selected-02)",
      "th-border-subtle-selected-03": "var(--cds-border-subtle-selected-03)",
      "th-border-tile-01": "var(--cds-border-tile-01)",
      "th-border-tile-02": "var(--cds-border-tile-02)",
      "th-border-tile-03": "var(--cds-border-tile-03)",
      "th-field-01": "var(--cds-field-01)",
      "th-field-02": "var(--cds-field-02)",
      "th-field-03": "var(--cds-field-03)",
      "th-field-hover-01": "var(--cds-field-hover-01)",
      "th-field-hover-02": "var(--cds-field-hover-02)",
      "th-field-hover-03": "var(--cds-field-hover-03)",
      "th-focus": "var(--cds-focus)",
      "th-focus-inset": "var(--cds-focus-inset)",
      "th-focus-inverse": "var(--cds-focus-inverse)",
      "th-highlight": "var(--cds-highlight)",
      "th-icon-disabled": "var(--cds-icon-disabled)",
      "th-icon-interactive": "var(--cds-icon-interactive)",
      "th-icon-inverse": "var(--cds-icon-inverse)",
      "th-icon-on-color": "var(--cds-icon-on-color)",
      "th-icon-on-color-disabled": "var(--cds-icon-on-color-disabled)",
      "th-icon-primary": "var(--cds-icon-primary)",
      "th-icon-secondary": "var(--cds-icon-secondary)",
      "th-interactive": "var(--cds-interactive)",
      "th-layer-01": "var(--cds-layer-01)",
      "th-layer-02": "var(--cds-layer-02)",
      "th-layer-03": "var(--cds-layer-03)",
      "th-layer-accent-01": "var(--cds-layer-accent-01)",
      "th-layer-accent-02": "var(--cds-layer-accent-02)",
      "th-layer-accent-03": "var(--cds-layer-accent-03)",
      "th-layer-accent-active-01": "var(--cds-layer-accent-active-01)",
      "th-layer-accent-active-02": "var(--cds-layer-accent-active-02)",
      "th-layer-accent-active-03": "var(--cds-layer-accent-active-03)",
      "th-layer-accent-hover-01": "var(--cds-layer-accent-hover-01)",
      "th-layer-accent-hover-02": "var(--cds-layer-accent-hover-02)",
      "th-layer-accent-hover-03": "var(--cds-layer-accent-hover-03)",
      "th-layer-active-01": "var(--cds-layer-active-01)",
      "th-layer-active-02": "var(--cds-layer-active-02)",
      "th-layer-active-03": "var(--cds-layer-active-03)",
      "th-layer-hover-01": "var(--cds-layer-hover-01)",
      "th-layer-hover-02": "var(--cds-layer-hover-02)",
      "th-layer-hover-03": "var(--cds-layer-hover-03)",
      "th-layer-selected-01": "var(--cds-layer-selected-01)",
      "th-layer-selected-02": "var(--cds-layer-selected-02)",
      "th-layer-selected-03": "var(--cds-layer-selected-03)",
      "th-layer-selected-disabled": "var(--cds-layer-selected-disabled)",
      "th-layer-selected-hover-01": "var(--cds-layer-selected-hover-01)",
      "th-layer-selected-hover-02": "var(--cds-layer-selected-hover-02)",
      "th-layer-selected-hover-03": "var(--cds-layer-selected-hover-03)",
      "th-layer-selected-inverse": "var(--cds-layer-selected-inverse)",
      "th-link-inverse": "var(--cds-link-inverse)",
      "th-link-inverse-active": "var(--cds-link-inverse-active)",
      "th-link-inverse-hover": "var(--cds-link-inverse-hover)",
      "th-link-primary": "var(--cds-link-primary)",
      "th-link-primary-hover": "var(--cds-link-primary-hover)",
      "th-link-secondary": "var(--cds-link-secondary)",
      "th-link-visited": "var(--cds-link-visited)",
      "th-overlay": "var(--cds-overlay)",
      "th-shadow": "var(--cds-shadow)",
      "th-skeleton-background": "var(--cds-skeleton-background)",
      "th-skeleton-element": "var(--cds-skeleton-element)",
      "th-slug-background": "var(--cds-slug-background)",
      "th-slug-background-hover": "var(--cds-slug-background-hover)",
      "th-slug-callout-aura-end": "var(--cds-slug-callout-aura-end)",
      "th-slug-callout-aura-start": "var(--cds-slug-callout-aura-start)",
      "th-slug-callout-gradient-bottom":
        "var(--cds-slug-callout-gradient-bottom)",
      "th-slug-callout-gradient-top": "var(--cds-slug-callout-gradient-top)",
      "th-slug-hollow-hover": "var(--cds-slug-hollow-hover)",
      "th-support-caution-major": "var(--cds-support-caution-major)",
      "th-support-caution-minor": "var(--cds-support-caution-minor)",
      "th-support-caution-undefined": "var(--cds-support-caution-undefined)",
      "th-support-error": "var(--cds-support-error)",
      "th-support-error-inverse": "var(--cds-support-error-inverse)",
      "th-support-info": "var(--cds-support-info)",
      "th-support-info-inverse": "var(--cds-support-info-inverse)",
      "th-support-success": "var(--cds-support-success)",
      "th-support-success-inverse": "var(--cds-support-success-inverse)",
      "th-support-warning": "var(--cds-support-warning)",
      "th-support-warning-inverse": "var(--cds-support-warning-inverse)",
      "th-text-disabled": "var(--cds-text-disabled)",
      "th-text-error": "var(--cds-text-error)",
      "th-text-helper": "var(--cds-text-helper)",
      "th-text-inverse": "var(--cds-text-inverse)",
      "th-text-on-color": "var(--cds-text-on-color)",
      "th-text-on-color-disabled": "var(--cds-text-on-color-disabled)",
      "th-text-placeholder": "var(--cds-text-placeholder)",
      "th-text-primary": "var(--cds-text-primary)",
      "th-text-secondary": "var(--cds-text-secondary)",
      "th-toggle-off": "var(--cds-toggle-off)",

      "th-layer": "var(--cds-layer)",
      "th-layer-active": "var(--cds-layer-active)",
      "th-layer-hover": "var(--cds-layer-hover)",
      "th-layer-selected": "var(--cds-layer-selected)",
      "th-layer-selected-hover": "var(--cds-layer-selected-hover)",
      "th-layer-accent": "var(--cds-layer-accent)",
      "th-layer-accent-hover": "var(--cds-layer-accent-hover)",
      "th-layer-accent-active": "var(--cds-layer-accent-active)",
      "th-field": "var(--cds-field)",
      "th-field-hover": "var(--cds-field-hover)",
      "th-border-subtle": "var(--cds-border-subtle)",
      "th-border-subtle-selected": "var(--cds-border-subtle-selected)",
      "th-border-strong": "var(--cds-border-strong)",
      "th-border-tile": "var(--cds-border-tile)",

      "th-button-separator": "var(--cds-button-separator)",
      "th-button-primary": "var(--cds-button-primary)",
      "th-button-secondary": "var(--cds-button-secondary)",
      "th-button-tertiary": "var(--cds-button-tertiary)",
      "th-button-danger-primary": "var(--cds-button-danger-primary)",
      "th-button-danger-secondary": "var(--cds-button-danger-secondary)",
      "th-button-danger-active": "var(--cds-button-danger-active)",
      "th-button-primary-active": "var(--cds-button-primary-active)",
      "th-button-secondary-active": "var(--cds-button-secondary-active)",
      "th-button-tertiary-active": "var(--cds-button-tertiary-active)",
      "th-button-danger-hover": "var(--cds-button-danger-hover)",
      "th-button-primary-hover": "var(--cds-button-primary-hover)",
      "th-button-secondary-hover": "var(--cds-button-secondary-hover)",
      "th-button-tertiary-hover": "var(--cds-button-tertiary-hover)",
      "th-button-disabled": "var(--cds-button-disabled)",
      "th-notification-background-error":
        "var(--cds-notification-background-error)",
      "th-notification-background-success":
        "var(--cds-notification-background-success)",
      "th-notification-background-info":
        "var(--cds-notification-background-info)",
      "th-notification-background-warning":
        "var(--cds-notification-background-warning)",
      "th-notification-action-hover": "var(--cds-notification-action-hover)",
      "th-notification-action-tertiary-inverse":
        "var(--cds-notification-action-tertiary-inverse)",
      "th-notification-action-tertiary-inverse-active":
        "var(--cds-notification-action-tertiary-inverse-active)",
      "th-notification-action-tertiary-inverse-hover":
        "var(--cds-notification-action-tertiary-inverse-hover)",
      "th-notification-action-tertiary-inverse-text":
        "var(--cds-notification-action-tertiary-inverse-text)",
      "th-notification-action-tertiary-inverse-text-on-color-disabled":
        "var(--cds-notification-action-tertiary-inverse-text-on-color-disabled)",
      "th-tag-background-red": "var(--cds-tag-background-red)",
      "th-tag-color-red": "var(--cds-tag-color-red)",
      "th-tag-hover-red": "var(--cds-tag-hover-red)",
      "th-tag-background-magenta": "var(--cds-tag-background-magenta)",
      "th-tag-color-magenta": "var(--cds-tag-color-magenta)",
      "th-tag-hover-magenta": "var(--cds-tag-hover-magenta)",
      "th-tag-background-purple": "var(--cds-tag-background-purple)",
      "th-tag-color-purple": "var(--cds-tag-color-purple)",
      "th-tag-hover-purple": "var(--cds-tag-hover-purple)",
      "th-tag-background-blue": "var(--cds-tag-background-blue)",
      "th-tag-color-blue": "var(--cds-tag-color-blue)",
      "th-tag-hover-blue": "var(--cds-tag-hover-blue)",
      "th-tag-background-cyan": "var(--cds-tag-background-cyan)",
      "th-tag-color-cyan": "var(--cds-tag-color-cyan)",
      "th-tag-hover-cyan": "var(--cds-tag-hover-cyan)",
      "th-tag-background-teal": "var(--cds-tag-background-teal)",
      "th-tag-color-teal": "var(--cds-tag-color-teal)",
      "th-tag-hover-teal": "var(--cds-tag-hover-teal)",
      "th-tag-background-green": "var(--cds-tag-background-green)",
      "th-tag-color-green": "var(--cds-tag-color-green)",
      "th-tag-hover-green": "var(--cds-tag-hover-green)",
      "th-tag-background-gray": "var(--cds-tag-background-gray)",
      "th-tag-color-gray": "var(--cds-tag-color-gray)",
      "th-tag-hover-gray": "var(--cds-tag-hover-gray)",
      "th-tag-background-cool-gray": "var(--cds-tag-background-cool-gray)",
      "th-tag-color-cool-gray": "var(--cds-tag-color-cool-gray)",
      "th-tag-hover-cool-gray": "var(--cds-tag-hover-cool-gray)",
      "th-tag-background-warm-gray": "var(--cds-tag-background-warm-gray)",
      "th-tag-color-warm-gray": "var(--cds-tag-color-warm-gray)",
      "th-tag-hover-warm-gray": "var(--cds-tag-hover-warm-gray)",
    },
    spacing: {
      0: "0",
      1: "1px",
      2: "2px",
      3: "3px",
      4: "4px",
      6: "6px",
      8: "8px",
      10: "10px",
      12: "12px",
      14: "14px",
      15: "15px",
      16: "16px",
      18: "18px",
      20: "20px",
      22: "22px",
      24: "24px",
      26: "26px",
      28: "28px",
      30: "30px",
      32: "32px",
      34: "34px",
      36: "36px",
      38: "38px",
      40: "40px",
      42: "42px",
      44: "44px",
      46: "46px",
      48: "48px",
      50: "50px",
      52: "52px",
      54: "54px",
      56: "56px",
      58: "58px",
      60: "60px",
      64: "64px",
      66: "66px",
      68: "68px",
      70: "70px",
      80: "80px",
      84: "84px",
      90: "90px",
      96: "96px",
      100: "100px",
      110: "110px",
      116: "116px",
      120: "120px",
      130: "130px",
      140: "140px",
      150: "150px",
      160: "160px",
      170: "170px",
      180: "180px",
      200: "200px",
      230: "230px",
      240: "240px",
      250: "250px",
      260: "260px",
      270: "270px",
      280: "280px",
      290: "290px",
      300: "300px",
      320: "320px",
      340: "340px",
      350: "350px",
      360: "360px",
      380: "380px",
      400: "400px",
      500: "500px",
      942: "942px",
      "105pct": "105%",
      "120pct": "120%",
    },
    minWidth: {
      16: "16px",
      250: "250px",
      320: "320px",
      350: "350px",
    },
    maxWidth: {
      "content-max": "1955px",
      72: "72px",
      320: "320px",
      480: "480px",
      600: "600px",
      920: "920px",
      1320: "1320px",
      "5/6": "83.333333%",
    },
    minHeight: {
      42: "42px",
      48: "48px",
      96: "96px"
    },
    maxHeight: {
      320: "320px",
    },
    borderWidth: {
      3: "3px",
    },
    borderRadius: {
      4: "4px",
      8: "8px",
      16: "16px",
      100: "100px",
    },
    boxShadow: {
      lightShadow: "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
      shadowMenu: "0px 4px 6px 0px rgba(0, 0, 0, 0.3)",
      sh: "0px 4px 6px 0px var(--cds-shadow)"
    },
    gridTemplateColumns: {
      min300: "repeat(auto-fill, minmax(300px, 1fr))",
    },
    screens: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      lgMax: "1024px",
      xl: "1200px",
      xxl: "1400px",
      xxxl: "1600px",
      betterhover: { raw: "(hover: hover)" },
    },
    zIndex: {
      10: 10,
      50: 50,
      100: 100,
      110: 110,
      120: 120,
      150: 150,
      200: 200,
      250: 250,
      300: 300,
      999: 999,
      8500: 8500,
      9000: 9000,
      9999: 9999,
    },
    transitionProperty: {
      spacing: "margin, padding",
      measurements: "width, height",
    },
    animation: {
      // "spin-faster": "spin 0.5s linear infinite",
    },
  },
};

export const twMerge = extendTailwindMerge({
  classGroups: {
    "font-size": Object.keys(theme.extend.fontSize).map((key) => `text-${key}`),
  },
});

export default theme;
