import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { api } from "services";
import { useShallow, useTaskCacheStore } from "stores";
import { getFullNameBaseEntity } from "helpers";
import { parseIsoString } from "utils";
import { checkListOptions, jobTitleMap } from "cnst";

const parsedData = (data) => {
  if (!data) return {};

  return {
    taskId: data.id,
    status: data?.status,
    createdBy: data?.createdBy ?? null,
    // user: { id: user?.id, role: user?.role },
    // mainOwner: data?.mainOwner,
    // assignees: data?.assignees,
    assignees: [
      ...(data?.mainOwner ? [{ ...data.mainOwner, owner: true }] : []),
      ...data?.assignees,
    ],
    chatClosedAt: data?.closedAt,
    isChatOpened: data?.isChatOpened ?? false,
    mainInfo: {
      driver: {
        id: data.driver.id,
        name: getFullNameBaseEntity(data.driver),
        hosLink: data.driver?.hosLink,
        trailerNumber: data.driver?.trailerNumber,
        truckNumber: data.driver?.truckNumber,
        motorCarrier: data?.driver?.company?.name,
        status: data?.driver?.status,
        notes: data.driver?.notes,
        company: data.driver?.company?.name,
        isPhoneExists: data.driver?.isPhoneExists
      },
      taskTypeOption: {
        value: data.jobType,
        text: jobTitleMap[data.jobType] ?? "",
      },
      title: data?.title,
      createdOn: parseIsoString(data?.createdAt),
      dueDate: data?.dueDate ? [data.dueDate] : [],
      checkList: checkListOptions.map((item) => ({
        ...item,
        checked: data?.checkList?.[item.value],
      })),
    },
    liveChat: { id: data.id, messages: data?.messages ?? [] },
    historyChat: { id: data.id, histories: data?.histories ?? [] },
  };
};

export const useTaskViewModel = ({ id }) => {
  const { initTaskCache, resetTaskCache } = useTaskCacheStore(
    useShallow((state) => ({
      initTaskCache: state.initTaskCache,
      resetTaskCache: state.resetTaskCache,
    }))
  );

  const { isFetching, isError, refetch } = useQuery({
    queryKey: [`task-${id}`],
    queryFn: () =>
      api.get({ path: `tasks/${id}`, role: "protected" }).then((data) => {
        initTaskCache(parsedData(data));
        return data;
      }),
    retry: false,
    gcTime: 0,
  });

  useEffect(() => {
    return () => resetTaskCache();
  }, []);

  return { refetch, isFetching, isError };
};
