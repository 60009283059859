import { Outlet } from "react-router-dom";
import { Suspense } from "react";

const PublicLayout = () => {
  return (
    <Suspense fallback="loading...">
      <Outlet />
    </Suspense>
  );
};

export default PublicLayout;
