import { newTaskSchema } from "schema";
import {
  TextInput,
  TextArea,
  FilterableMultiSelect,
  DatePicker,
  DatePickerInput,
} from "@carbon/react";
import CustomSelect from "components/CustomSelect";
import Autosuggest from "components/Autosuggest";

import { autosuggestSource } from "helpers";
import { jobTypeOptions } from "cnst";

export const newTaskForm = {
  defaultValues: {
    title: "",
    jobType: "",
    driver: null,
    roomId: "",
    assignees: [],
    dueDate: "",
    comment: "",
  },

  formInputs: {
    title: {
      Component: TextInput,
      name: "title",
      inputProps: {
        labelText: "Title",
        placeholder: "Add subject",
        className: "col-span-full",
      },
    },
    jobType: {
      Component: CustomSelect,
      name: "jobType",
      inputProps: {
        labelText: "Task Type",
        options: jobTypeOptions,
        className: "col-span-full",
        defaultOption: {
          value: "",
          text: "Select task Type",
        },
      },
    },
    driver: {
      Component: Autosuggest,
      name: "driver",
      withValue: (option) => ({ value: option?.text ?? "" }),
      inputProps: {
        renderInput: (props) => (
          <TextInput
            light
            placeholder="Search driver"
            labelText="Driver"
            {...props}
          />
        ),
        fancyMode: false,
        containerClass: "col-span-full",
        source: autosuggestSource,
      },
    },
    assignees: {
      Component: FilterableMultiSelect,
      name: "assignees",
      withValue: (value) => ({ initialSelectedItems: value }),
      change: (cb) => ({ onChange: ({ selectedItems }) => cb(selectedItems) }),
      inputProps: {
        titleText: "Assign to",
        placeholder: "Search assignee...",
        items: [],
        itemToString: (item) => (item ? item.text : ""),
        className: "col-span-full",
      },
    },
    dueDate: {
      Component: (props) => (
        <DatePicker light datePickerType="single" {...props}>
          <DatePickerInput
            id="date-picker-input-id-start"
            autoComplete="off"
            placeholder="mm/dd/yyyy"
            labelText="Due date"
            size="lg"
          />
        </DatePicker>
      ),
      name: "dueDate",
      inputProps: {
        className: "col-span-full data-picker-full",
      },
    },
    comment: {
      Component: TextArea,
      name: "comment",
      inputProps: {
        placeholder: "Comment",
        className: "col-span-full",
        labelText: "Comment",
      },
    },
  },

  schema: newTaskSchema,
};
