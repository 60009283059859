import axios from "axios";
import { API_URL } from "cnst";

const axiosClient = axios.create({
  baseURL: API_URL,
  timeout: 15000,
  timeoutErrorMessage: "Server not responding. Please try again later",
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
