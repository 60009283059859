import { useNavigate } from "react-router-dom";
import { useTheme } from "hooks/useTheme";
import { useAuth } from "hooks/useAuth";
import { clsx } from "utils";
import { useShallow, useUser } from "stores";
import { avaStylesByRoleMap } from "cnst";

import Avatar from "components/Avatar";
import { OverflowMenuItem, OverflowMenu } from "@carbon/react";

import { AsleepFilled, LightFilled } from "@carbon/icons-react";
import { ReactComponent as Logo } from "assets/icons/logo.svg";

const themeIcons = {
  light: LightFilled,
  dark: AsleepFilled,
};

const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const { role, logout } = useAuth();
  const user = useUser(useShallow((store) => store.user));
  
  const forwardTo = (path) => () => navigate(path);
  
  const stylesByRole = avaStylesByRoleMap[role];
  const ThemeIcon = themeIcons[theme];
  const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`;

  return (
    <header className="fixed left-0 right-0 w-full h-48 px-16 flex justify-between items-center text-white bg-gray-100 border-b border-solid border-0 border-th-border-subtle-01 z-200">
      <div className="flex items-center gap-8" onClick={forwardTo("/dashboard")}>
        <Logo
          className="betterhover:cursor-pointer"
        />
        <div className="text-14 font-semibold">
          Tasks <span className="font-normal">Waves</span>
        </div>
      </div>

      <div className="flex items-center">
        <ThemeIcon
          className="mr-30 betterhover:cursor-pointer"
          size="20"
          onClick={toggleTheme}
        />

        <span className="mr-16 text-14">{fullName}</span>

        <Avatar
          name={fullName}
          className={clsx("shrink-0 mr-4", stylesByRole)}
        />

        <OverflowMenu
          flipped
          size="md"
          aria-label="overflow-menu"
          iconClass="fill-white"
          className="!bg-gray-100 !text-gray-30"
        >
          <OverflowMenuItem
            onClick={forwardTo("/dashboard/profile")}
            className="!bg-gray-100 !text-gray-30"
            itemText="Profile settings"
          />
          <OverflowMenuItem
            onClick={logout}
            className="!bg-gray-100 !text-gray-30"
            itemText="Sign out"
          />
        </OverflowMenu>
      </div>
    </header>
  );
};

export default Header;
