import { forwardRef } from "react";
import { cn } from "utils";
import PhoneInput from "react-phone-input-2";
import { WarningFilled } from "@carbon/icons-react";

const PhoneInputBaseElement = (
  {
    id,
    name,
    country = "us",
    labelText,
    labelClass = "",
    className = "",
    containerClass = "",
    inputClass = "",
    invalid = false,
    invalidText = "",
    required = false,
    formatted = true,
    onChange = () => {},
    ...restProps
  },
  ref
) => {
  return (
    <div className={cn(className)}>
      {labelText && (
        <div className="leading-none">
          <label
            htmlFor={name}
            className={cn(
              "inline-block mb-8 text-12 text-th-text-secondary",
              labelClass
            )}
          >
            {labelText}
          </label>
        </div>
      )}

      <div className="relative">
        <PhoneInput
          disableDropdown
          containerClass={cn(
            "phone-container border-b border-none border-solid border-th-border-strong-01 bg-th-field-02",
            containerClass
          )}
          inputClass={cn(
            "w-full h-40 pl-16 pr-40 text-14 text-th-text-primary tracking-16 placeholder:text-th-text-placeholder",
            inputClass
          )}
          onChange={(value, country, e, formattedValue) => {
            formatted ? onChange(formattedValue) : onChange(value);
          }}
          country={country}
          {...restProps}
          inputProps={{
            ...(ref && { ref }),
            ...(id && { id }),
            ...(name && { name }),
            ...{ ...(labelText && { id: name }) },
          }}
        />

        {invalid && (
          <WarningFilled className="absolute top-1/2 -translate-y-1/2 right-16 fill-th-support-error" />
        )}
      </div>
      {invalid && (
        <div className="mt-4 text-12 text-th-text-error">{invalidText}</div>
      )}
    </div>
  );
};

export default forwardRef(PhoneInputBaseElement);
