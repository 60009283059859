import { useSideNavExpand } from "stores";
import { cn } from "utils";

const MainLayout = ({ children, className }) => {
  const isExpanded = useSideNavExpand((store) => store.isExpanded);
  return (
    <div className={cn(isExpanded ? "ml-300" : "ml-48", className)}>
      {children}
    </div>
  );
};

export default MainLayout;
