import { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTheme } from './useTheme';
import { useShallow, useUser } from 'stores';
import { api } from 'services';
import ErrorMessage from 'components/ErrorMessage';
import { Loading } from '@carbon/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { theme } = useTheme();
  const [token, setToken] = useLocalStorage('tokens', null);
  const [role, setRole] = useState(null);
  const setUser = useUser(useShallow((store) => store.setUser));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    isAuthenticated() ? goToProtectedRoute() : goToPublicRoute();
  }, [token]);

  function isAuthenticated () {
    return token ? true : false;
  }

  async function getUser () {
    return api
      .get({ path: '/users/me' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  async function goToProtectedRoute () {
    try {
      error && setError(false);
      setLoading(true);
      const data = await getUser();
      if (data?.role) {
        setRole(data.role);
        setUser(data);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  function goToPublicRoute () {
    setRole('public');
  }

  const login = async (res) => {
    const { tokens } = res;
    setToken(tokens);
  };
  const logout = () => {
    // resetUser();
    setToken(null);
    window.location.reload();
  };

  const values = { role, login, logout };

  return (
    <>
      <AuthContext.Provider value={values}>
        {loading && !error && (
          <div className="py-32 flex justify-center">
            <Loading withOverlay={false} small />
          </div>
        )}

        {!loading && error ? (
          <ErrorMessage
            className="w-full h-full"
            classContent="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            onClick={goToProtectedRoute}
          />
        ) : null}

        {!loading && !error && role ? children : null}

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          theme={theme}
        />
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => useContext(AuthContext);
