import { createElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSchema } from 'schema';

import { Button, TextInput, Loading } from '@carbon/react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { api } from 'services';
import { useAuth } from 'hooks/useAuth';

const defaultValues = {
  email: '',
  password: '',
};
const formInputs = [
  {
    Component: TextInput,
    name: 'email',
    restInputProps: {
      labelText: 'Enter your email',
      placeholder: 'Ex: john@gmail.com',
    },
  },
  {
    Component: (props) => <TextInput.PasswordInput {...props} />,
    name: 'password',
    restInputProps: {
      labelText: 'Password',
      placeholder: 'Enter your password',
    },
  },
];

const SignIn = () => {
  const { login } = useAuth();
  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api.post({ role: 'public', path: '/auth/sign-in', values }),
    onSuccess: (data) => login(data),
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(getSchema(...Object.keys(defaultValues))),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => mutate(values));

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <form
        className="shrink-0 w-full max-w-480 px-24 py-32 bg-th-layer-01"
        onSubmit={handleSubmitForm}
      >
        <h1 className="mb-32 text-32 font-bold">Sign in</h1>
        <div className="mb-32 flex flex-col gap-32">
          {formInputs.map(({ Component, name, restInputProps }) => (
            <Controller
              key={name}
              name={name}
              control={control}
              render={({ field }) => {
                const { ref, ...restField } = field;
                const errorMessage = errors?.[name]?.message;
                return createElement(Component, {
                  id: name,
                  light: true,
                  invalid: !!errorMessage,
                  invalidText: errorMessage,
                  ...restInputProps,
                  ...restField,
                });
              }}
            />
          ))}
        </div>

        <Button disabled={isPending} type="submit">
          Sign in
          {isPending && <Loading small className="ml-16" withOverlay={false} />}
        </Button>
      </form>
    </div>
  );
};

export default SignIn;
