import { useDropzone } from 'react-dropzone';
import { cn } from 'utils';
import { toast } from 'react-toastify';
import { validateFile } from 'helpers';

const FileUpload = ({ children, onFilesAccepted, multiple = false, containerClass }) => {

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    // accept: accessFiles[type],
    validator: validateFile,
    onDropAccepted: (acceptedFile) => {
      onFilesAccepted && onFilesAccepted([acceptedFile[0]]);
    },
    onDropRejected: (rejectedFile) => {toast.warn(rejectedFile[0].errors[0].message);},
  });

  return (
    <div {...getRootProps()} className={cn(containerClass)}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default FileUpload;
