import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PublicLayout from "layouts/PublicLayout";
import ProtectedLayout from "layouts/ProtectedLayout";
import { SignIn, SignUp } from "views/Auth";

// lazy import pages
const Profile = lazy(() => import("views/Profile"));
const Room = lazy(() => import("views/Room"));
const Drivers = lazy(() => import("views/Drivers"));
const MotorCarriers = lazy(() => import("views/MotorCarriers"));
const AdminSettings = lazy(() => import("views/AdminSettings"));
const HomePage = lazy(() => import("views/HomePage"));

// PUBLIC
const publicRoutes = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      // {
      //   path: "/signup",
      //   element: <SignUp />,
      // },
      // {
      //   path: "/reset",
      //   element: <ResetPassword />,
      // },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

// ADMIN
const adminRoutes = [
  {
    path: "/dashboard",
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "rooms/:roomId",
        element: <Room />,
      },
      {
        path: "clients",
        children: [
          { index: true, element: <Navigate to="/clients/motor-carriers" /> },
          { path: "motor-carriers", element: <MotorCarriers /> },
          { path: "drivers", element: <Drivers /> },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "admin-settings",
        element: <AdminSettings />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
];

// MANAGER
const managerRoutes = [
  {
    path: "/dashboard",
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "rooms/:roomId",
        element: <Room />,
      },
      {
        path: "clients",
        children: [
          { index: true, element: <Navigate to="/clients/motor-carriers" /> },
          { path: "motor-carriers", element: <MotorCarriers /> },
          { path: "drivers", element: <Drivers /> },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
];

// WORKER
const workerRoutes = [
  {
    path: "/dashboard",
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "rooms/:roomId",
        element: <Room />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
];

// CHECKER
const checkerRoutes = [
  {
    path: "/dashboard",
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "rooms/:roomId",
        element: <Room />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
];

const ROLES_ROUTES = {
  public: publicRoutes,
  admin: adminRoutes,
  manager: managerRoutes,
  worker: workerRoutes,
  checker: checkerRoutes,
  developer: adminRoutes
};

export const getRoutes = (role) => ROLES_ROUTES[role] ?? publicRoutes;
