import { clsx } from "clsx";
import { months } from "cnst";
import { twMerge } from "twThemeConfig";

//
const cn = (...classes) => twMerge(clsx(classes));
//
const getInitials = (name) => {
  const nameArray = name.split(" ");
  const firstNameIn = nameArray[0].charAt(0).toUpperCase();
  const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  return firstNameIn + lastNameIn;
};

const addQueryParams = (values) => {
  window.location.search
    .replace("?", "")
    .split("&")
    .forEach((str) => {
      if (str) {
        const elem = str.split("=");
        if (elem) values[elem[0]] = elem[1];
      }
    });
  return values;
};

// const createObjectBaseMatchVal = (obj1, obj2) => {
//   const newObj = { ...obj1 };
//   for (const key in obj2) {
//     if (newObj.hasOwnProperty(key)) {
//       newObj[key] = obj2[key] ?? "";
//     }
//   }

//   return newObj;
// };
const decapitalizeText = (text) => {
  return (text && text[0].toLowerCase() + text.slice(1)) || text;
};

const createObjectBaseMatchVal = (obj1, obj2) => {
  const newObj = { ...obj1 };
  for (const key in obj2) {
    if (newObj.hasOwnProperty(key)) {
      newObj[key] = obj2[key] ?? "";
    }
  }

  return newObj;
};

const convertSecToMs = (s)=> s * 1000;

const formattedIsoStrDate = (string) => {
  if (!string) return null;
  const date = new Date(string);
  const day = `0${date.getDate()}`.slice(-2);
  const month = date.getMonth() + 1;
  const monthInStr = months[month];
  const year = `${date.getFullYear()}`;
  return `${monthInStr} ${day}, ${year}`;
};

const parseIsoString = (string, options = {
  year: "numeric", month: "short", day: "2-digit", hour: "2-digit", hour12: false, minute: "2-digit",
}) => {
  if (!string) return null;
  const date = new Date(string);
  return date.toLocaleString("en-US", options);
};

const getTime = (string) => {
  return parseIsoString(string, {
    hour: "2-digit", minute: "2-digit", hourCycle: "h23",
  });
};

const getDateRange = (dateRange) => {
  const [start, end] = dateRange || [];
  const options = {
    month: "short", day: "2-digit", year: "numeric",
  };
  const dateStart = parseIsoString(start, options);
  const dateEnd = parseIsoString(end, options);

  if (dateStart === dateEnd) {
    return dateStart;
  }

  return `${dateStart}${dateEnd ? ` - ${dateEnd}` : ""}`;
};

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

const getUtcDate = (date) => {
  const timeOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - timeOffset).toISOString();
};

const convertFileSizeToMB = (fileSizeInBytes) => {
  if (!fileSizeInBytes) return null;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMB.toFixed(3);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
  if (typeof milliseconds !== "number") return "";
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function secondsToTime(seconds) {
  const s = parseInt(seconds % 60);
  const m = parseInt((seconds / 60) % 60);
  return m + ":" + s;
}

function isApple() {
  var expression = /(Mac|iPhone|iPod|iPad)/i;
  return expression.test(navigator.platform);
}

function isControl(event) {
  if (isApple()) {
    return event.metaKey;
  }
  return event.ctrlKey;
}

function isPaste(event) {
  if (isControl(event)) {
    return event.key === "v";
  }
  return false;
}

function getInsertedImageIntoDoc(files) {
  if (!files) {
    return null;
  }

  const images = [];
  for (const clipboardItem of files) {
    if (clipboardItem.type.startsWith("image/")) {
      images.push(clipboardItem);
    }
  }

  if (images?.length === 0) return null;

  return images;
}

function sanitizeObject(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (!value) {
        delete obj[key];
      }
    }
  }
  return obj;
}

function formatSecondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return { formattedHours, formattedMinutes, formattedSeconds };
}

export {
  cn,
  clsx,
  addQueryParams,
  getInitials,
  decapitalizeText,
  createObjectBaseMatchVal,
  formattedIsoStrDate,
  parseIsoString,
  getDateRange,
  getUtcDate,
  getTime,
  addMinutes,
  secondsToTime,
  convertMsToTime,
  getInsertedImageIntoDoc,
  isPaste,
  convertFileSizeToMB,
  sanitizeObject,
  formatSecondsToTime,
  convertSecToMs
};
