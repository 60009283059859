import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
// import { Theme } from "@carbon/react";

const ThemeContext = createContext();

export const matchingTheme = {
  light: "cds--g10 cds--layer-one",
  dark: "cds--g100 cds--layer-one",
};

export const ThemeProvider = ({ children }) => {
  const [themeInStorage, setThemeInStorage] = useLocalStorage("theme", "dark");
  const [theme, setTheme] = useState(() => themeInStorage ?? "dark");

  useEffect(() => {
    document.body.classList = matchingTheme[theme];
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    setThemeInStorage(newTheme);
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {/* <Theme theme={matchingTheme[theme]} className="min-h-full"> */}
      {children}
      {/* </Theme> */}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
