import useAudio from 'hooks/useAudio';

import { Button } from '@carbon/react';

import { PauseFilled, PlayFilledAlt } from '@carbon/icons-react';

function MessageAudio ({ data }) {
  const { isPlaying, progressInMs, durationInMs, displayTime, toggle, changeProgress } = useAudio(data?.link);

  return (
    <div className="flex items-center justify-between gap-8">
      <Button onClick={toggle} kind="ghost">
        {isPlaying ? <PauseFilled /> : <PlayFilledAlt />}
      </Button>

      <input
        type="range"
        className="cursor-pointer appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-th-border-strong-02 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-14 [&::-webkit-slider-thumb]:w-14 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-th-border-inverse"
        value={progressInMs}
        min={0}
        max={durationInMs}
        onChange={changeProgress}
      />

      {displayTime && <span className="text-14">{displayTime}</span>}
    </div>
  );
}

export default MessageAudio;
