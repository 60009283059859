import { cn, convertFileSizeToMB } from 'utils';

import { DocumentMultiple_01 } from '@carbon/react/icons';
import { Link } from '@carbon/react';

function MessageFile ({ data, styles }) {
  const { file, link, } = data || {};

  const { secondaryColor, linkColor, fileIconColor, fileIconContainerColor } = styles || {};

  return (

    <div className="flex gap-8">
      <div className={cn('shrink-0 w-48 h-48 flex justify-center items-center rounded-4', fileIconContainerColor)}>
        <DocumentMultiple_01 className={cn(fileIconColor)} />
      </div>

      <div className="flex flex-col justify-center">
        <Link
          className={cn('flex flex-nowrap mb-4 text-14', linkColor)}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <span className="line-clamp-1">{file?.fileName}</span>
          {`.${file?.fileExtension}`}
        </Link>
        <p className={cn('text-12', secondaryColor)}>
          ({convertFileSizeToMB(file?.fileSize)} MB)
        </p>
      </div>
    </div>
  );
}

export default MessageFile;
