import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useShallow, useVoiceCallApi } from "stores";
import { AnimatePresence, motion } from "framer-motion";
import { Device } from "@twilio/voice-sdk";
import { api } from "services";

import { Button } from "@carbon/react";
import Stopwatch from "./Stopwatch";
import { toast } from "react-toastify";
import { PhoneOff, User, VolumeUp, VolumeMute } from "@carbon/react/icons";
import { cn } from "utils";

// const Device = lazy(() => import('@twilio/voice-sdk').then(module => ({ default: module.Device })));

const STATUSES = {
  CONNECTING: "Connecting",
  CALLING: "Calling",
  ON_CALL: "On call",
  CALL_ENDED: "Call ended",
  CALL_ERROR: "Error occurred"
};

const VoiceCall = () => {
  const { show, setClose } = useVoiceCallApi(useShallow(({ show, setClose }) => ({ setClose, show })));
  const isOpen = Boolean(show);
  const renderProps = show;

  return ReactDOM.createPortal(<AnimatePresence mode="wait">
    {isOpen && (<motion.div
      initial={{ y: 20, opacity: 0.2 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="fixed bottom-15 left-15 z-9999"
    >
      <VoiceCallPanel handleClose={setClose} {...renderProps} />
    </motion.div>)}
  </AnimatePresence>, document.body);
};

const VoiceCallPanel = ({
  id, name, company, handleClose
}) => {
  const [status, setStatus] = useState(STATUSES.CONNECTING);
  const [isMute, setIsMute] = useState(false)
  const device = useRef(null);
  const call = useRef(null);
  const stopWatchRef = useRef(null);

  async function handleCall() {
    try {
      if (device.current) {
        call.current = await device.current.connect({
          params: { To: id }
        });

        call.current.on("ringing", () => {
          console.log("ringing");
          stopWatchRef?.current?.start();
          setStatus(STATUSES.ON_CALL);
        });

        call.current.on("accept", () => {
          console.log("The incoming call was accepted or the outgoing call's media session has finished setting up.");
        });

        call.current.on("cancel", () => {
          console.log("The call has been canceled.");
        });

        call.current.on("disconnect", () => {
          console.log("The call has been disconnected.");
          handleHangup();
        });

        call.current.on("reject", () => {
          console.log("The call was rejected.");
        });

        call.current.on("error", (error) => {
          console.log("CALL triggered - an error has occurred");
          toast.error(error?.description || "Something went wrong. Please try again.");
          handleClose();
        });

      } else {
        throw new Error("Unable to make call");
      }
    } catch (error) {
      console.log(error);
    }
  };

  function toggleAudioMute() {
    call.current?.mute(!isMute);
    setIsMute(!isMute);
  }

  function handleHangup() {
    device.current?.disconnectAll();
    setStatus(STATUSES.CALL_ENDED);
    stopWatchRef?.current?.stop();
    setTimeout(handleClose, 1000);
  };

  useEffect(() => {
    async function initializeDevice() {
      try {
        const accessToken = await api.getVoiceCallAccessToken();

        device.current = new Device(accessToken, {
          // logLevel: 1,
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
          debug: true,
          tokenRefreshMs: 30000
        });
        // device.current.on("destroyed", () => console.log("Destroyed"));
        device.current.on("error", async (twilioError) => {
          console.log("Device triggered. An error has occurred: name-", twilioError.name);
          console.log("Device triggered. An error has occurred: code-", twilioError.code);

          if (twilioError.code === 20104) {
            const newToken = await api.getVoiceCallAccessToken();
            device.current.updateToken(newToken);
            handleCall();
          }

          setStatus(STATUSES.CALL_ERROR)
          setTimeout(handleClose, 3000);
        });
        device.current.on("tokenWillExpire", async () => {
          console.log("Device triggered - tokenWillExpire");
          const newToken = await api.getVoiceCallAccessToken();
          device.current.updateToken(newToken);
        });

        handleCall();

      } catch (err) {
        setStatus(STATUSES.CALL_ERROR)
        setTimeout(handleClose, 3000);
      }
    };

    initializeDevice();

    return () => {
      device.current?.destroy();
    };
  }, []);

  const VolumeIcon = isMute ? VolumeMute : VolumeUp;

  return (<div className="w-320 p-16 bg-th-layer-01 shadow-sh">
    <div className="mb-10 flex items-center justify-between gap-8">
      <div className="flex items-center gap-8">
        <div
          className="flex-shrink-0 w-42 h-42 flex items-center justify-center rounded-full bg-th-layer-02"
        >
          <User size="20" />
        </div>

        <div className="flex flex-col gap-6">
          <p className="text-th-text-primary font-semibold line-clamp-1 leading-100">{name}</p>
          {company &&
            <p className="text-14 text-th-text-secondary line-clamp-1 leading-100" title={company}>{company}</p>}
        </div>
      </div>
      <span
        className={cn("shrink-0 text-12 text-th-text-helper", status === STATUSES.CALL_ERROR && "text-th-text-error")}
      >
          {status}
        </span>
    </div>

    <div className="flex justify-between items-center gap-12">
      <Stopwatch
        ref={stopWatchRef}
        defaultIsRunning={false}
        className="flex-1 text-26 text-th-text-primary leading-100"
      />

      <Button
        kind="tertiary"
        size="sm"
        disabled={status !== STATUSES.ON_CALL}
        className="p-12 w-auto h-auto flex justify-center items-center"
        onClick={toggleAudioMute}
      >
        <VolumeIcon />
      </Button>

      <Button
        kind="tertiary"
        size="sm"
        disabled={status === STATUSES.CALL_ERROR || status === STATUSES.CALL_ENDED}
        className="p-12 w-auto h-auto flex justify-center items-center"
        onClick={handleHangup}
      >
        <PhoneOff />
      </Button>
    </div>

  </div>);
};


export default VoiceCall;
