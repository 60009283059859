import { useLocation } from "react-router-dom";
import { SideNavMenuItem, SkeletonText } from "@carbon/react";
import { useRooms, useShallow } from "stores";
import TaskCount from "./TaskCount";
import { taskColIdByRoleMap } from "cnst";
import { useAuth } from "hooks/useAuth";
import { cn } from "utils";

const NavRoomList = ({ navigateTo }) => {
  const { role: userRole } = useAuth();
  const { pathname } = useLocation();

  const roomNames = useRooms(useShallow((store) => store.roomNames || {}));

  const roomList = Object.entries(roomNames).map(([key, value]) => ({
    id: key,
    taskColId: taskColIdByRoleMap[userRole],
    title: value,
  }));

  if (!roomList.length) {
    return <SkeletonText heading />;
  }

  console.count("render Nav room list");
  return (
    <>
      {roomList.map((room) => (
        <SideNavMenuItem
          key={room.id}
          isActive={pathname.includes(`/rooms/${room.id}`)}
          className={cn("cursor-pointer")}
          onClick={navigateTo(`/dashboard/rooms/${room.id}`)}
        >
          {room.title} <TaskCount roomId={room.id} taskColId={room.taskColId} />
        </SideNavMenuItem>
      ))}
    </>
  );
};

export default NavRoomList;
