import { stateOptions, statusOptions } from "data/options";
import { TextInput, TextArea } from "@carbon/react";
import CustomSelect from "components/CustomSelect";
import PhoneInputBaseElement from "elements/PhoneInputBaseElement";

const motorCarrierForm = {
  defaultValues: {
    name: "",
    dot: "",
    status: "active",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    contactName: "",
    phone: "",
    email: "",
    notes: "",
  },

  formInputs: {
    firstGroup: {
      type: "groupName",
      name: "grGeneralInfo",
      inputProps: {
        text: "General info",
        className: "col-span-full text-14 font-medium",
      },
    },
    name: {
      Component: TextInput,
      name: "name",
      type: "input",
      inputProps: {
        labelText: "Motor carrier",
        placeholder: "Enter motor carrier",
        className: "col-start-1 col-end-13",
      },
    },
    dot: {
      Component: TextInput,
      name: "dot",
      type: "input",
      inputProps: {
        labelText: "DOT",
        placeholder: "Enter DOT",
        className: "col-start-1 col-end-7",
      },
    },
    status: {
      Component: CustomSelect,
      name: "status",
      type: "input",
      inputProps: {
        labelText: "Status",
        options: statusOptions,
        className: "col-start-7 col-end-13",
        defaultOption: {
          value: "",
          text: "Select status",
        },
      },
    },

    secondGroup: {
      type: "groupName",
      name: "grAddress",
      inputProps: {
        text: "Address",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    city: {
      Component: TextInput,
      name: "city",
      type: "input",
      inputProps: {
        labelText: "City",
        placeholder: "Enter city",
        className: "col-start-1 col-end-7",
      },
    },
    state: {
      Component: CustomSelect,
      name: "state",
      type: "input",
      inputProps: {
        labelText: "State",
        options: stateOptions,
        className: "col-start-7 col-end-13",
        defaultOption: {
          value: "",
          text: "Select state",
          props: { disabled: true },
        },
      },
    },
    address: {
      Component: TextInput,
      name: "address",
      type: "input",
      inputProps: {
        labelText: "Address",
        placeholder: "Enter address",
        className: "col-span-full",
      },
    },
    zipCode: {
      Component: TextInput,
      name: "zipCode",
      type: "input",
      inputProps: {
        labelText: "Zip Code",
        placeholder: "Enter Zip Code",
        className: "col-start-1 col-end-7",
      },
    },

    thirdGroup: {
      type: "groupName",
      name: "grContactPerson",
      inputProps: {
        text: "Contact person",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    contactName: {
      Component: TextInput,
      name: "contactName",
      type: "input",
      inputProps: {
        labelText: "Full name",
        placeholder: "Enter full name",
        className: "col-start-1 col-end-7",
      },
    },
    phone: {
      Component: PhoneInputBaseElement,
      name: "phone",
      type: "input",
      inputProps: {
        labelText: "Phone",
        placeholder: "Enter phone",
        className: "col-start-7 col-end-13",
      },
    },
    email: {
      Component: TextInput,
      name: "email",
      type: "input",
      inputProps: {
        labelText: "Email",
        placeholder: "Enter email",
        className: "col-span-full",
      },
    },

    fourthGroup: {
      type: "groupName",
      name: "grNotes",
      inputProps: {
        text: "Notes",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    notes: {
      Component: TextArea,
      name: "notes",
      type: "input",
      inputProps: {
        placeholder: "Notes",
        className: "col-span-full mb-20",
        labelText: "",
      },
    },
  },
};

export { motorCarrierForm };
