import { Fragment, createElement, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "services";

import { cn, createObjectBaseMatchVal, sanitizeObject } from "utils";
import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { driverSchema } from "schema";

import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";

import { driverForm } from "./formData";
import withFetchFormData from "hoc/withFetchFormData";

const getMcOptions = (data) => {
  const [mcOptions] = data || [];
  const options = mcOptions?.entities.map((room) => ({
    value: room.id,
    text: room.name,
  }));

  return { key: "companyId", options: options || [] };
};

const UpdateDriver = ({
  unmountCb,
  formValues,
  fetchedData,
  method,
  path,
  queryKey,
  heading,
  className,
  onClose,
  primaryButtonText,
}) => {
  const queryClient = useQueryClient();
  const { defaultValues, formInputs } = driverForm;

  useEffect(() => {
    return () => {
      unmountCb && unmountCb();
    };
  }, []);

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api[method]({ path, values }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: createObjectBaseMatchVal(defaultValues, formValues),
    resolver: yupResolver(driverSchema),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => {
    values = sanitizeObject(values);
    mutate(values);
  });

  if (fetchedData.options.length) {
    formInputs[fetchedData.key].inputProps.options = fetchedData.options;
  } else {
    delete formInputs[fetchedData.key];
  }

  return (
    <>
      <SidebarHeading onClose={onClose}>{heading}</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 grid grid-cols-12 gap-16">
          {Object.values(formInputs).map((input) => {
            const { type, Component, name, inputProps } = input;
            let render = null;
            switch (type) {
              case "input":
                render = (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                      const { ref, ...restField } = field;
                      const errorMessage = errors?.[name]?.message;
                      const telegramUserName = name === "telegramUserName";
                      return createElement(Component, {
                        id: name,
                        light: true,
                        invalid: Boolean(errorMessage),
                        invalidText: errorMessage,
                        ...inputProps,
                        ...restField,
                        ...(telegramUserName ? { disabled: true } : {}),
                      });
                    }}
                  />
                );
                break;
              case "groupName":
                render = (
                  <span className={inputProps.className}>
                    {inputProps.text}
                  </span>
                );
                break;
              default:
            }

            return <Fragment key={name}>{render}</Fragment>;
          })}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText={primaryButtonText}
          onClose={onClose}
          isPending={isPending}
        />
      </form>
    </>
  );
};

export default withFetchFormData(UpdateDriver, {
  paths: ["/companies"],
  transformData: getMcOptions,
});
