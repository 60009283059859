import { statusOptions, roleOptions } from "data/options";
import { updateRoomSchema } from "schema";
import { TextInput, TextArea, MultiSelect } from "@carbon/react";
import CustomSelect from "components/CustomSelect";
import PhoneInputBaseElement from "elements/PhoneInputBaseElement";

const roomForm = {
  defaultValues: {
    name: "",
    status: "active",
  },

  formInputs: [
    {
      Component: TextInput,
      name: "name",
      inputProps: {
        labelText: "Room Name",
        placeholder: "Room name",
        className: "col-span-full",
      },
    },
    {
      Component: CustomSelect,
      name: "status",
      inputProps: {
        labelText: "Status",
        options: statusOptions,
        className: "col-span-full",
      },
    },
  ],

  updateRoomSchema,
};

const botForm = {
  defaultValues: {
    name: "",
    status: "active",
    token: "",
    roomId: "",
  },

  formInputs: {
    botName: {
      Component: TextInput,
      name: "name",
      inputProps: {
        labelText: "Bot Name",
        placeholder: "Enter bot name",
        className: "col-start-1 col-end-7",
      },
    },
    status: {
      Component: CustomSelect,
      name: "status",
      inputProps: {
        labelText: "Status",
        options: statusOptions,
        className: "col-start-7 col-end-13",
      },
    },
    token: {
      Component: TextInput,
      name: "token",
      inputProps: {
        labelText: "ID token",
        placeholder: "Enter ID token",
        className: "col-span-full",
      },
    },
    roomId: {
      Component: CustomSelect,
      name: "roomId",
      inputProps: {
        labelText: "Room name",
        options: [],
        className: "col-span-full",
        defaultOption: {
          value: "",
          text: "Select room",
          props: { disabled: true },
        },
      },
    },
  },
};

const employeeForm = {
  defaultValues: {
    firstName: "",
    lastName: "",
    role: "",
    status: "active",
    roomIds: [],
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    notes: "",
  },

  formInputs: {
    firstGroup: {
      type: "groupName",
      name: "grGeneralInfo",
      inputProps: {
        text: "General info",
        className: "col-span-full text-14 font-medium",
      },
    },
    firstName: {
      Component: TextInput,
      name: "firstName",
      type: "input",
      inputProps: {
        labelText: "First Name",
        placeholder: "Enter first name",
        className: "col-start-1 col-end-7",
      },
    },
    lastName: {
      Component: TextInput,
      name: "lastName",
      type: "input",
      inputProps: {
        labelText: "Last Name",
        placeholder: "Enter last name",
        className: "col-start-7 col-end-13",
      },
    },
    role: {
      Component: CustomSelect,
      name: "role",
      type: "input",
      inputProps: {
        labelText: "Assign role",
        options: roleOptions,
        className: "col-start-1 col-end-7",
        defaultOption: {
          value: "",
          text: "Select role",
        },
      },
    },
    status: {
      Component: CustomSelect,
      name: "status",
      type: "input",
      inputProps: {
        labelText: "Employee status",
        options: statusOptions,
        className: "col-start-7 col-end-13",
        defaultOption: {
          value: "",
          text: "Select status",
        },
      },
    },
    roomIds: {
      Component: MultiSelect,
      name: "roomIds",
      type: "input",
      withValue: (value) => ({ initialSelectedItems: value }),
      change: (cb) => ({ onChange: ({ selectedItems }) => cb(selectedItems) }),
      inputProps: {
        titleText: "Room",
        label: "Select rooms",
        items: [],
        itemToString: (item) => (item ? item.text : ""),
        className: "col-span-full",
      },
    },
    secondGroup: {
      type: "groupName",
      name: "grContactDetails",
      inputProps: {
        text: "Contact details",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    phone: {
      Component: PhoneInputBaseElement,
      name: "phone",
      type: "input",
      inputProps: {
        labelText: "Phone",
        placeholder: "Enter phone",
        className: "col-span-full",
      },
    },
    email: {
      Component: TextInput,
      name: "email",
      type: "input",
      inputProps: {
        labelText: "Email",
        placeholder: "Enter email",
        className: "col-span-full",
      },
    },
    password: {
      Component: (props) => (
        <div className="col-span-full">
          <TextInput.PasswordInput {...props} />
        </div>
      ),
      name: "password",
      type: "input",
      inputProps: {
        labelText: "Password",
        placeholder: "Password",
        className: "col-span-full",
      },
    },
    confirmPassword: {
      Component: (props) => (
        <div className="col-span-full">
          <TextInput.PasswordInput {...props} />
        </div>
      ),
      name: "confirmPassword",
      type: "input",
      inputProps: {
        labelText: "Confirm password",
        placeholder: "Confirm password",
        className: "col-span-full",
      },
    },
    thirdGroup: {
      type: "groupName",
      name: "grNotes",
      inputProps: {
        text: "Notes",
        className: "mt-8 col-span-full text-14 font-medium",
      },
    },
    notes: {
      Component: TextArea,
      name: "notes",
      type: "input",
      inputProps: {
        placeholder: "Notes",
        className: "col-span-full mb-20",
        labelText: "",
      },
    },
  },
};

export { roomForm, botForm, employeeForm };
