import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { WebSocketProvider } from "hooks/useWebsocket";

import Header from "components/Header";
import LeftSidebar from "components/LeftSidebar";
import MainLayout from "./MainLayout";
import RightDrawer from "components/RightDrawer";
import Popup from "components/Popup";
import VoiceCall from "components/VoiceCall";

const ProtectedLayout = () => {
  return (
    <WebSocketProvider>
      <Header />
      <LeftSidebar />
      <MainLayout className="p-16 pt-64">
        <Suspense fallback="loading...">
          <Outlet />
        </Suspense>
      </MainLayout>
      <RightDrawer />
      <Popup />
      <VoiceCall />
    </WebSocketProvider>
  );
};

export default ProtectedLayout;
