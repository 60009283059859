import { getErrorMessage } from "helpers";
import axiosClient from "libs/axios.js";

const defaultHeaders = {
  "Content-Type": "multipart/form-data",
};

class ApiService {
  constructor() {
    this.instance = axiosClient;
  }

  async getVoiceCallAccessToken() {
    let accessToken = JSON.parse(localStorage.getItem("voiceCallAccessToken"));

    if (!accessToken) {
      accessToken = await this.get({ path: "/twilio/access-token" }).then(({ token }) => token);
      localStorage.setItem("voiceCallAccessToken", JSON.stringify(accessToken));
      return accessToken;
    }

    const expiresAt = JSON.parse(atob(accessToken.split(".")[1])).exp * 1000;
    const currentTimestamp = new Date().getTime();
    const tokenIsExpired = expiresAt < currentTimestamp;
    if (tokenIsExpired) {
      accessToken = await this.get({ path: "/twilio/access-token" }).then(({ token }) => token);
      localStorage.setItem("voiceCallAccessToken", JSON.stringify(accessToken));
      return accessToken;
    }

    return accessToken;

  }

  async getAccessToken() {
    const { accessToken } = JSON.parse(localStorage.getItem("tokens"));
    return accessToken;
  }

  async updateTokens() {
    const { refreshToken } = JSON.parse(localStorage.getItem("tokens"));
    try {
      const res = await this.instance.post("/auth/refresh", null, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      });
      localStorage.setItem("tokens", JSON.stringify(res.data));
    } catch (error) {
      if (error?.response?.status === 400) {
        localStorage.setItem("tokens", null);
        window.location.href = "/";
      } else {
        throw error?.response?.status;
      }
    }
  }

  async get({
    role = "protected", useBaseUrl = true, path, headers = defaultHeaders, requestOptions = {},
  }) {
    if (role === "protected") {
      const accessToken = await this.getAccessToken();
      headers.Authorization = `Bearer ${accessToken}`;
    }

    try {
      const response = await this.instance.get(path, {
        ...(useBaseUrl ? {} : { baseURL: "" }), headers, ...requestOptions,
      });
      return response?.data ?? null;
    } catch (error) {
      if (error?.response?.status === 401) {
        await this.updateTokens();
        return await this.get({ role, path, headers, requestOptions });
      } else {
        const message = getErrorMessage({
          method: "get", path, response: error?.response,
        });
        throw message;
      }
    }
  }

  async post({
    role = "protected", path, headers = defaultHeaders, values = {}, requestOptions = {},
  }) {
    try {
      if (role === "protected") {
        const accessToken = await this.getAccessToken();
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await this.instance.post(path, values, {
        headers, ...requestOptions,
      });
      return response?.data ?? null;
    } catch (error) {
      if (error?.response?.status === 401) {
        await this.updateTokens();
        return await this.post({
          role, path, values, headers, requestOptions,
        });
      } else {
        const message = getErrorMessage({
          method: "post", path, response: error?.response,
        });
        throw message;
      }
    }
  }

  async patch({
    role = "protected", path, headers = defaultHeaders, values = {}, requestOptions = {},
  }) {
    try {
      if (role === "protected") {
        const accessToken = await this.getAccessToken();
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await this.instance.patch(path, values, {
        headers, ...requestOptions,
      });
      return response.data ?? null;
    } catch (error) {
      if (error?.response?.status === 401) {
        await this.updateTokens();
        return await this.patch({
          role, path, values, headers, requestOptions,
        });
      } else {
        const message = getErrorMessage({
          method: "patch", path, response: error?.response,
        });
        throw message;
      }
    }
  }

  async put({
    role = "protected", path, headers = defaultHeaders, values = {}, requestOptions = {},
  }) {
    try {
      if (role === "protected") {
        const accessToken = await this.getAccessToken();
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await this.instance.put(path, values, {
        headers, ...requestOptions,
      });
      return response.data ?? null;
    } catch (error) {
      if (error?.response?.status === 401) {
        await this.updateTokens();
        return await this.put({
          role, path, values, headers: defaultHeaders, requestOptions,
        });
      } else {
        const message = getErrorMessage({
          method: "put", path, response: error?.response,
        });
        throw message;
      }
    }
  }

  async delete(params) {
  }
}

export const api = new ApiService();
