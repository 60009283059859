import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";
import { jobTypeOptions, jobTagTypeMap } from "cnst";
import { cn } from "utils";
import { toast } from "react-toastify";

import { OverflowMenu, OverflowMenuItem, Tag, Loading } from "@carbon/react";

import { AddLarge, RequestQuote } from "@carbon/icons-react";

const TaskTypeSelect = ({ taskId, defaultOption, className }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const { mutate, isPending } = useMutation({
    mutationFn: ({ values }) => api.patch({ path: `tasks/${taskId}`, values }),
    onSuccess: (_, { option }) => setSelectedOption(option),
    onError: (error) => toast.error(error),
  });

  const tagType = jobTagTypeMap[selectedOption?.value] ?? "gray";
  const renderIcon = selectedOption ? RequestQuote : AddLarge;

  const handleSetOption = (option) => () => handleMutation(option);

  function handleMutation(option) {
    const formData = new FormData();
    formData.append("jobType", option.value);
    mutate({ values: formData, option });
  }

  return (
    <div className={cn("flex items-center gap-8", className)}>
      {selectedOption ? (
        <Tag disabled={isPending} type={tagType}>
          {selectedOption.text}
        </Tag>
      ) : null}

      {isPending ? (
        <Loading small withOverlay={false} />
      ) : (
        <OverflowMenu
          flipped
          size="sm"
          aria-label="menu"
          light
          renderIcon={renderIcon}
        >
          {jobTypeOptions.map((option) => {
            return (
              <OverflowMenuItem
                key={option.text}
                disabled={selectedOption.value === option.value}
                onClick={handleSetOption(option)}
                itemText={option.text}
              />
            );
          })}
        </OverflowMenu>
      )}
    </div>
  );
};

export default TaskTypeSelect;
