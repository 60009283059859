import { taskColIdByStatusMap } from "cnst";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useShallow } from "zustand/react/shallow";
import _ from "lodash";

export const useSideNavExpand = create((set) => ({
  isExpanded: true,
  setIsExpanded: () => set((state) => ({ isExpanded: !state.isExpanded })),
}));

export const useDrawerStore = create(
  immer((set) => ({
    show: null, // userDrawer = {key: "", props: {}}
    setOpen: (payload) =>
      set((state) => {
        state.show = { ...payload };
      }),
    setClose: () =>
      set((state) => {
        state.show = null;
      }),
  }))
);

export const useVoiceCallApi = create(
  immer((set) => ({
    show: null,
    setOpen: (payload) =>
      set((state) => {
        state.show = { ...payload };
      }),
    setClose: () =>
      set((state) => {
        state.show = null;
      }),
  }))
)

export const usePopupStore = create(
  immer((set) => ({
    show: null, // userPopup = {key: "", props: {}}
    setOpen: (payload) =>
      set((state) => {
        state.show = { ...payload };
      }),
    setClose: () =>
      set((state) => {
        state.show = null;
      }),
  }))
);

const initialUser = null;
const useUser = create(
  immer((set) => ({
    user: null,
    setUser: (payload) =>
      set((state) => {
        state.user = { ...state.user, ...payload };
      }),
    resetUser: () =>
      set((state) => {
        state.user = initialUser;
      }),
  }))
);

const useRooms = create(
  immer((set, get) => ({
    roomNames: {},
    totalByTasks: {},
    cardsByTasks: {},

    setRoomNames: (payload) =>
      set((state) => {
        state.roomNames = payload;
      }),

    moveTask: (payload) =>
      set((state) => {
        const { previousStatus, task } = payload || {};
        const { roomId, id: taskId, status } = task || {};
        const currentColId = taskColIdByStatusMap[previousStatus];
        const nextColId = taskColIdByStatusMap[status];

        if (currentColId !== undefined) {
          const sourceArray = get().cardsByTasks[roomId]?.[currentColId];
          const sourceTotal = get().totalByTasks[roomId]?.[currentColId];

          const taskIndex = sourceArray?.findIndex(({ id }) => id === taskId);
          // Removing a task from the previous column
          if (typeof taskIndex !== "undefined" && taskIndex !== -1) {
            const updatedSourceArray = [
              ...sourceArray.slice(0, taskIndex),
              ...sourceArray.slice(taskIndex + 1),
            ];
            state.cardsByTasks[roomId][currentColId] = updatedSourceArray;
          }

          // minus total from the previous column
          if (
            currentColId !== "newTasks" &&
            typeof sourceTotal !== "undefined" &&
            sourceTotal > 0
          ) {
            state.totalByTasks[roomId][currentColId] -= 1;
          }
        }

        // Adding a task to the next column
        if (nextColId !== undefined) {
          if (state.cardsByTasks?.[roomId]?.hasOwnProperty(nextColId)) {
            state.cardsByTasks[roomId][nextColId].unshift(task);
          }
          if (state.totalByTasks?.[roomId]?.hasOwnProperty(nextColId)) {
            state.totalByTasks[roomId][nextColId] += 1;
          }
        }
      }),

    removeTask: (payload) =>
      set((state) => {
        const { roomId, taskId, status } = payload || {};
        const taskColId = taskColIdByStatusMap[status];

        if (taskColId !== undefined) {
          const sourceArray = get().cardsByTasks[roomId]?.[taskColId];
          const sourceTotal = get().totalByTasks[roomId]?.[taskColId];

          const taskIndex = sourceArray?.findIndex(({ id }) => id === taskId);
          if (typeof taskIndex !== "undefined" && taskIndex !== -1) {
            const updatedSourceArray = [
              ...sourceArray.slice(0, taskIndex),
              ...sourceArray.slice(taskIndex + 1),
            ];
            state.cardsByTasks[roomId][taskColId] = updatedSourceArray;
          }

          if (typeof sourceTotal !== "undefined" && sourceTotal > 0) {
            state.totalByTasks[roomId][taskColId] -= 1;
          }
        }
      }),

    updateMessageNotification: (notification) =>
      set((state) => {
        const { messages, roomId, taskStatus } = notification;
        const taskColId = taskColIdByStatusMap[taskStatus];
        let tasks = _.cloneDeep(get()?.cardsByTasks?.[roomId]?.[taskColId]);
        if (!Array.isArray(tasks) || tasks.length === 0) return;

        for (const message of messages) {
          const taskIndex = tasks.findIndex((el) => el.id === message.taskId);
          if (taskIndex !== -1) {
            tasks[taskIndex].unreadMessages += 1;
          }
        }
        state.cardsByTasks[roomId][taskColId] = tasks;
      }),

    setTotalByTasks: ({ roomId, payload }) =>
      set((state) => {
        state.totalByTasks[roomId] = {
          ...state.totalByTasks[roomId],
          ...payload,
        };
      }),
    setCardsByTasks: ({ roomId, payload }) =>
      set((state) => {
        state.cardsByTasks[roomId] = payload;
      }),
    resetCardsByTask: ({ roomId, taskColId, payload }) =>
      set((state) => {
        state.cardsByTasks[roomId][taskColId] = payload;
      }),
    setCardsByTaskId: ({ roomId, taskColId, payload }) =>
      set((state) => {
        let sourceArray = get().cardsByTasks[roomId]?.[taskColId];
        if (typeof sourceArray !== "undefined") {
          sourceArray = [...sourceArray, ...payload];

          state.cardsByTasks[roomId][taskColId] = sourceArray.filter(
            (obj, index) => {
              return index === sourceArray.findIndex((o) => obj.id === o.id);
            }
          );
        }
      }),

    setTotalByIds: ({ roomId, taskColId, payload }) =>
      set((state) => {
        if (!state.totalByTasks?.[roomId]) {
          console.log("setTotalByIds - roomId not found");
          state.totalByTasks[roomId] = {};
        }
        if (!state.totalByTasks[roomId]?.[taskColId]) {
          console.log("setTotalByIds - taskColId not found");
          state.totalByTasks[roomId][taskColId] = 0;
        }

        state.totalByTasks[roomId][taskColId] += payload;
      }),
    setCardsByIds: ({ roomId, taskColId, payload }) =>
      set((state) => {
        if (!state.cardsByTasks?.[roomId]) {
          console.log("roomId not found");
          state.cardsByTasks[roomId] = {};
        }
        if (!state.cardsByTasks[roomId]?.[taskColId]) {
          console.log("taskColId not found");
          state.cardsByTasks[roomId][taskColId] = [];
        }

        state.cardsByTasks[roomId][taskColId].push(payload);
      }),
  }))
);

const initialTaskCache = {
  taskId: null,
  status: "",
  createdBy: "",
  assignees: [],
  chatClosedAt: "",
  isChatOpened: false,
  mainInfo: {},
  historyChat: {},
  liveChat: {},
};
const useTaskCacheStore = create(
  immer((set, get) => ({
    ...initialTaskCache,

    setChatClosedAt: (payload) => {
      set((state) => {
        state.chatClosedAt = payload;
      });
    },
    setChatOpened: (payload) => {
      set((state) => {
        state.isChatOpened = payload;
      });
    },
    closeChat: () => {
      set((state) => {
        state.status = "readyForCheck";
        state.chatClosedAt = "";
        state.isChatOpened = false;
      });
    },
    setStatus: (payload) => {
      set((state) => {
        if (state.chatClosedAt) {
          state.chatClosedAt = "";
        }
        state.status = payload;
      });
    },
    setAssignees: (payload) => {
      set((state) => {
        state.assignees = payload;
      });
    },
    addNewMessage: (payload) => {
      set((state) => {
        const { messages } = payload;
        const filteredMessages = messages.filter((m) => m.taskId === state.taskId);

        if (filteredMessages.length === 0) return;

        let clonedCurrentMessages = _.cloneDeep(get().liveChat?.messages);
        for (const message of filteredMessages) {
          if (state.chatClosedAt && message?.from === "customer") {
            state.chatClosedAt = "";
            state.isChatOpened = true;
          }

          if (!clonedCurrentMessages) {
            clonedCurrentMessages = [];
          }

          clonedCurrentMessages.push(message);
        }

        state.liveChat.messages = clonedCurrentMessages;
      });
    },
    addNewHistory: (payload) => {
      set((state) => {
        if (payload?.taskId !== state.taskId) return;

        if (!state.historyChat?.histories) {
          state.historyChat.histories = [];
        }

        state.historyChat.histories.push(payload);
      });
    },
    initTaskCache: (payload) => set(() => payload),
    resetTaskCache: () => set(() => initialTaskCache),
  }))
);

export { useShallow, useRooms, useUser, useTaskCacheStore };

// const RoomNamesType = {
//   roomNames: { roomId: "string" },
// };
// const TotalByTasksType = {
//   totalByTasks: { roomId: { taskColId: "string" } },
// };
// const CardsByTasksType = {
//   cardsByTasks: { roomId: { taskColId: [] } },
// };

// roomId => taskColId => []
