import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";
import { cn } from "utils";
import { toast } from "react-toastify";

import { Checkbox, Loading } from "@carbon/react";

const AsyncCheckItem = ({ taskId, item, className }) => {
  const [isChecked, setIsChecked] = useState(item?.checked ?? false);

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api.patch({ path: `tasks/${taskId}`, values }),
    onSuccess: () => setIsChecked((prev) => !prev),
    onError: (error) => toast.error(error),
  });

  function handleMutation(checked) {
    const formData = new FormData();
    formData.append(`checkList[${item?.value}]`, checked);
    mutate(formData);
  }

  return (
    <li className={cn("py-10 relative", className)}>
      <Checkbox
        disabled={isPending}
        id={item?.text}
        labelText={item?.text ?? ""}
        checked={isChecked}
        className="!mb-0 pr-24"
        onChange={(_, { checked }) => handleMutation(checked)}
      />
      {isPending && (
        <span className="absolute top-1/2 -translate-y-1/2 right-0">
          <Loading small withOverlay={false} />
        </span>
      )}
    </li>
  );
};

export default AsyncCheckItem;
