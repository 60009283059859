import { forwardRef, useEffect, useRef } from 'react';
import { usePopupStore, useShallow, useTaskCacheStore, useUser } from 'stores';
import { useMutation } from '@tanstack/react-query';
import { api } from 'services';
import { historyCardStyleMap } from 'cnst';

import { cn, getInsertedImageIntoDoc } from 'utils';
import useScrollToBottomOnMessageChange from 'hooks/useScrollToBottomOnMessageChange';

import { Button, Loading } from '@carbon/react';
import TextArea from 'components/TextArea';
import FileUpload from 'components/FileUpload';
import TextMessage from 'components/Message/TextMessage';
import DocumentMessage from 'components/Message/DocumentMessage';

import { Attachment, SendFilled } from '@carbon/react/icons';

const HistoryChatTab = ({ className }) => {
  const data = useTaskCacheStore(useShallow((state) => state.historyChat));
  const { anchorMessageRef } = useScrollToBottomOnMessageChange(data.histories);

  return (
    <div className={cn('flex justify-between flex-col', className)}>
      <Header />
      <div className="flex-1 flex flex-col overflow-auto">
        <HistoriesList
          ref={anchorMessageRef}
          histories={data.histories}
          className="flex-1"
        />
      </div>
      <ShowHistoryInput taskId={data.id} />
    </div>
  );
};

function Header () {
  return (
    <div className="sticky top-0 h-48 px-24 flex items-center text-14 font-semibold bg-th-layer-02">
      Activity
    </div>
  );
}

const HistoriesList = forwardRef(({ className, histories }, ref) => {
  return (
    <div className={cn('flex flex-col justify-end px-24 pt-12', className)}>
      {histories.map((history) => {
        return <History key={history.id} history={history} />;
      })}
      <div ref={ref} />
    </div>
  );
});

function History ({ history }) {
  const styles = historyCardStyleMap[history.type] || {};

  switch (history?.type) {
    case 'log':
      return <TextMessage styles={styles} data={history} />;
    case 'text':
      return <TextMessage infoPosition="top" styles={styles} data={history} />;
    case 'document':
      return (
        <DocumentMessage infoPosition="top" styles={styles} data={history} className="h-100" />
      );
    default:
      return null;
  }
}

const withShowInput = (WrappedComponent) => (props) => {
  const user = useUser(useShallow((state) => state.user));
  const assignees = useTaskCacheStore(useShallow((state) => state.assignees));

  const userIsAssigned = assignees.some((assignee) => assignee.id === user.id);

  const showComponent = userIsAssigned || user?.role !== 'worker';

  if (showComponent) return <WrappedComponent {...props} />;

  return null;
};

export function MessageInput ({ taskId, className }) {
  const textareaRef = useRef();
  const handleOpen = usePopupStore(useShallow((store) => store.setOpen));
  const { mutate, isPending } = useMutation({
    mutationFn: (values) => {
      api.post({ path: `tasks/${taskId}/histories`, values });
    },
    onSuccess: () => {textareaRef.current.value = '';},
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    function handlePaste (evt) {
      // evt.preventDefault();

      const files = getInsertedImageIntoDoc(evt?.clipboardData?.files);
      if (files) {
        openFilesSendModal(files);
      }
    }

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  function openFilesSendModal (files) {
    handleOpen({
      key: 'sendingFiles',
      props: {
        path: `tasks/${taskId}/histories`,
        files,
        taskId,
      },
    });
  }

  function handleKeyUp (evt) {
    const textareaValue = textareaRef.current.value.trim();
    if (evt.keyCode === 13 && !evt.shiftKey && Boolean(textareaValue)) {
      evt.preventDefault();
      handleSendMessage();
    }
  }

  function handleSendMessage () {
    const textareaValue = textareaRef.current.value.trim();
    if (!textareaValue) return false;

    handleMutate(textareaValue);
  }

  function handleMutate (text) {
    const formData = new FormData();
    formData.append('type', 'text');
    formData.append('content', text);
    formData.append('taskId', taskId);
    mutate(formData);
  }

  return (
    <div
      className={cn(
        'w-full flex border-0 border-t border-solid border-th-border-subtle-02',
        className
      )}
    >
      <FileUpload containerClass="self-end" onFilesAccepted={openFilesSendModal}>
        <Button disabled={isPending} kind="ghost">
          <Attachment />
        </Button>
      </FileUpload>

      <TextArea
        ref={textareaRef}
        readOnly={isPending}
        onKeyDown={handleKeyUp}
        className="flex-1"
        placeholder="Type something..."
      />

      <Button
        disabled={isPending}
        kind="ghost"
        className="self-end"
        onClick={handleSendMessage}
      >
        {isPending ? <Loading small withOverlay={false} /> : <SendFilled />}
      </Button>
    </div>
  );
}

const ShowHistoryInput = withShowInput(MessageInput);

export default HistoryChatTab;
