import { createElement } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "services";

import { cn, createObjectBaseMatchVal } from "utils";
import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { roomForm } from "./formData";

import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";

const UpdateRoom = ({
  formValues,
  method,
  path,
  queryKey,
  heading,
  className,
  onClose,
  primaryButtonText,
}) => {
  const queryClient = useQueryClient();
  const { defaultValues, formInputs, updateRoomSchema } = roomForm;

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api[method]({ path: path, values }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: createObjectBaseMatchVal(defaultValues, formValues),
    resolver: yupResolver(updateRoomSchema),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => mutate(values));

  return (
    <>
      <SidebarHeading onClose={onClose}>{heading}</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 grid grid-cols-12 gap-16">
          {formInputs.map(({ Component, name, inputProps }) => (
            <Controller
              key={name}
              name={name}
              control={control}
              render={({ field }) => {
                const { ref, ...restField } = field;
                const errorMessage = errors?.[name]?.message;
                return createElement(Component, {
                  id: name,
                  light: true,
                  invalid: Boolean(errorMessage),
                  invalidText: errorMessage,
                  ...inputProps,
                  ...restField,
                });
              }}
            />
          ))}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText={primaryButtonText}
          onClose={onClose}
          isPending={isPending}
        />
      </form>
    </>
  );
};

export default UpdateRoom;
