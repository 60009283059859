import Avatar from 'components/Avatar';
import { avaStylesByRoleMap } from 'cnst';
import { getFullNameBaseEntity } from 'helpers';
import { cn, convertSecToMs, parseIsoString } from 'utils';

function TextMessage ({ data, styles, infoPosition = 'bottom', className }) {
  const { content: text, createdAt, telegramTimestamp, user, isEdited } = data || {};

  const { card, secondaryColor, infoFlex } = styles || {};
  const fullName = getFullNameBaseEntity(user);
  const timestampOrCreatedAt = telegramTimestamp ? convertSecToMs(telegramTimestamp) : createdAt;
  const messageSendingTime = parseIsoString(timestampOrCreatedAt);

  return (
    <div className={cn('relative', card, className)}>
      {infoPosition === 'top' && (
        <div className="mb-12 flex items-center justify-between gap-8 text-12 text-th-text-secondary">
          <div className="flex items-center gap-4">
            <Avatar
              className={cn('w-24 h-24', avaStylesByRoleMap[user?.role])}
              name={fullName}
            />
            {fullName}
          </div>
          {messageSendingTime}
        </div>
      )}

      <p
        className="text-14 break-words leading-130"
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {infoPosition === 'bottom' && (
        <div
          className={cn(
            'mt-4 flex items-center justify-between gap-8 text-12',
            secondaryColor,
            infoFlex
          )}
        >
          <span>{messageSendingTime}</span>
          {user && <span>{fullName}</span>}
          {isEdited && <span className="flex-1 text-right">(edited)</span>}
        </div>
      )}
    </div>
  );
}

export default TextMessage;
