import { useAuth } from "./useAuth";
import { permissions } from "cnst";

const usePermissions = () => {
  const { role: userRole } = useAuth();

  const hasPermission = (permissionName) => {
    return permissions[permissionName]?.includes(userRole);
  };

  return { hasPermission };
};

export default usePermissions;
