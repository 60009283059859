import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";
import { cn } from "utils";
import { toast } from "react-toastify";

import { OverflowMenu, OverflowMenuItem, Loading } from "@carbon/react";

import { RequestQuote } from "@carbon/icons-react";
import { useShallow, useTaskCacheStore, useUser } from "stores";
import { taskOptionsByStatusAndRole, taskOptionByStatusMap } from "cnst";

const StatusSelect = ({ className }) => {
  const taskId = useTaskCacheStore(useShallow((state) => state.taskId));
  const status = useTaskCacheStore(useShallow((state) => state.status));
  const createdBy = useTaskCacheStore(useShallow((state) => state.createdBy));
  const setStatus = useTaskCacheStore(useShallow((state) => state.setStatus));
  const user = useUser(useShallow((state) => state.user));

  const options = taskOptionsByStatusAndRole[status]?.[user.role];
  const selectedOption = useMemo(() => taskOptionByStatusMap[status], [status]);
  const showMenu =
    createdBy === "worker" ? options : user.role !== "worker" && options;

  const { mutate, isPending } = useMutation({
    mutationFn: ({ values }) => api.patch({ path: `tasks/${taskId}`, values }),
    onSuccess: (_, { status }) => setStatus(status),
    onError: (error) => toast.error(error),
  });

  const handleSetOption = (option) => () => handleMutation(option);

  function handleMutation(option) {
    const formData = new FormData();
    formData.append("status", option.value);
    mutate({ values: formData, status: option.value });
  }

  return (
    <div className={cn("flex items-center", className)}>
      {selectedOption ? (
        <span className="text-14 mr-8">{selectedOption.text}</span>
      ) : null}

      {showMenu ? (
        <>
          {isPending ? (
            <Loading small withOverlay={false} />
          ) : (
            <OverflowMenu flipped size="sm" light renderIcon={RequestQuote}>
              {options.map((option) => {
                return (
                  <OverflowMenuItem
                    key={option.text}
                    disabled={selectedOption.value === option.value}
                    onClick={handleSetOption(option)}
                    itemText={option.text}
                  />
                );
              })}
            </OverflowMenu>
          )}
        </>
      ) : null}
    </div>
  );
};

export default StatusSelect;
