import * as yup from "yup";

export function getSchema(...args) {
  const schema = args.reduce((acc, key) => {
    if (fields.hasOwnProperty(key)) acc[key] = fields[key];
    return acc;
  }, {});
  return yup.object().shape(schema);
}

const firstName = yup
  .string()
  .trim()
  .required("First name is required")
  .matches(/^[a-zA-Z- ]+$/gi, "Enter correct first name. Ex: John")
  .min(2, "Minimum 2 characters allowed")
  .max(64, "Maximum 64 characters allowed");

const lastName = yup
  .string()
  .trim()
  .required("Last name is required")
  .matches(/^[a-zA-Z- ]+$/gi, "Enter correct last name. Ex: Doe")
  .min(2, "Minimum 2 characters allowed")
  .max(64, "Maximum 64 characters allowed");

const phone = yup.string().required("Phone is required");

const email = yup
  .string()
  .required("Email is required")
  .email("Email is not valid")
  .matches(/^(|.{6,})$/, "Minimum 6 characters allowed")
  .max(100, "Maximum 100 characters allowed");

const currentPassword = yup
  .string()
  .required("Please enter current password")
  // .transform((x) => (x === "" ? null : x))
  // .matches(/^[a-z0-9]+$|^$/i, "Password cannot contain space")
  .matches(/^\S+$/, "Password cannot contain space")
  .min(6, "Minimum 6 characters allowed")
  .max(16, "Maximum 16 characters allowed");

const password = yup
  .string()
  .trim()
  .required("Please enter password")
  // .matches(/^[a-z0-9]+$/i, "Password cannot contain space")
  .matches(/^\S+$/, "Password cannot contain space")
  .min(6, "Minimum 6 characters allowed")
  .max(16, "Maximum 16 characters allowed");

const confirmPassword = yup
  .string()
  .trim()
  .oneOf([yup.ref("password"), null], "Password are not matching");

const newPassword = yup
  .string()
  .trim()
  .when("currentPassword", (currentPassword, schema) => {
    const pass = currentPassword.join(" ");
    return pass
      ? schema
          .required("New password is required")
          .matches(/^\S+$/, "New password cannot contain space")
          .min(6, "Minimum 6 characters allowed")
          .max(16, "Maximum 16 characters allowed")
      : schema.test({
          test: (newPassword) => !newPassword,
          message: "Input current password",
        });
  });

const confirmNewPassword = yup
  .string()
  .trim()
  .oneOf([yup.ref("newPassword"), null], "New password are not matching");

export const updateRoomSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Room name is required")
    // .matches(
    //   /^[a-zA-Z0-9_]+$/gi,
    //   "Only letters, digits and underscore are allowed"
    // )
    .max(30, "Maximum 30 characters allowed"),
  status: yup.string().required("Status is required"),
});

export const updateBotSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Bot name is required")
    // .matches(
    //   /^[a-zA-Z0-9_]+$/gi,
    //   "Only letters, digits and underscore are allowed"
    // )
    // .min(2, "Minimum 2 characters allowed")
    .max(30, "Maximum 30 characters allowed"),
  status: yup.string().required("Status is required"),
  token: yup
    .string()
    .required("Token is requred")
    .max(60, "Maximum 60 characters allowed"),
  roomId: yup.string().required("Room is required"),
});

export const getEmployeeSchema = (operation) => {
  const create = yup.object().shape({
    firstName,
    lastName,
    role: yup.string().required("Role is required"),
    status: yup.string().required("Status is required"),
    phone: yup
      .string()
      .matches(/^(|.{5,})$/, "Minimum 5 characters allowed")
      .max(25, "Maximum 25 characters allowed"),
    email,
    password,
    confirmPassword,
    notes: yup.string().max(300, "Maximum 300 characters allowed"),
  });

  const update = yup.object().shape({
    firstName,
    lastName,
    role: yup.string().required("Role is required"),
    status: yup.string().required("Status is required"),
    phone: yup
      .string()
      .matches(/^(|.{5,})$/, "Minimum 5 characters allowed")
      .max(25, "Maximum 25 characters allowed"),
    email,
    notes: yup.string().max(300, "Maximum 300 characters allowed"),
  });

  const schemas = {
    create,
    update,
  };

  return schemas[operation];
};
export const mcSchema = yup.object().shape({
  name: yup
    .string()
    .required("Motor carrier is required")
    .max(80, "Maximum 80 characters allowed"),
  dot: yup
    .string()
    .matches(/^[0-9]*$/gi, "DOT must contain only digits")
    .max(20, "Maximum 30 characters allowed"),
  city: yup.string().max(20, "Maximum 20 characters allowed"),
  address: yup.string().max(255, "255 Maximum characters allowed"),
  zipCode: yup
    .string()
    .matches(/^[0-9]*$/gi, "Zip code must contain only digits")
    .test(
      "len",
      "Zip code must be exactly 5 characters",
      (val) => val.length === 5
    ),
  contactName: yup
    .string()
    .matches(/^[a-zA-Z- ]+$/gi, "Enter correct full name. Ex: John Doe")
    .min(2, "Minimum 2 characters allowed")
    .max(64, "Maximum 64 characters allowed"),
  phone: yup
    .string()
    .matches(/^(|.{5,})$/, "Minimum 5 characters allowed")
    .max(25, "Maximum 25 characters allowed"),
  email: yup
    .string()
    .email("Email is not valid")
    .matches(/^(|.{6,})$/, "Minimum 6 characters allowed")
    .max(100, "Maximum 100 characters allowed"),
  notes: yup.string().max(300, "Maximum 300 characters allowed"),
});

export const driverSchema = yup.object().shape({
  firstName,
  lastName,
  cdl: yup.string().max(30, "Maximum 30 characters allowed"),
  status: yup.string().required("Status is required"),
  hosLink: yup.string().max(150, "150 Maximum characters allowed"),
  truckNumber: yup.string().max(20, "20 Maximum characters allowed"),
  trailerNumber: yup.string().max(20, "20 Maximum characters allowed"),
  phone: yup
    .string()
    // .matches(/^(|.{5,})$/, "Minimum 5 characters allowed")
    .test("len", "Minimum 5 characters allowed", (val) => val.length > 5)
    .max(25, "Maximum 25 characters allowed"),
  email: yup
    .string()
    .email("Email is not valid")
    .matches(/^(|.{6,})$/, "Minimum 6 characters allowed")
    .max(100, "Maximum 100 characters allowed"),
  notes: yup.string().max(300, "Maximum 300 characters allowed"),
});

export const newTaskSchema = yup.object().shape({
  title: yup.string().max(100, "Maximum 100 characters allowed"),
  jobType: yup.string().required("Task type is required"),
  driver: yup
    .object()
    .nullable()
    .test("driver-option", "Driver is required", (value) => {
      if (value === null) return false;
      return Object.keys(value).length !== 0;
    }),
  comment: yup.string().max(300, "Maximum 300 characters allowed"),
});

const fields = {
  firstName,
  lastName,
  phone,
  email,
  password,
  currentPassword,
  newPassword,
  confirmPassword,
  confirmNewPassword,
};
