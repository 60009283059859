import { useState, useEffect, useRef, memo, useImperativeHandle, forwardRef } from "react";
import { formatSecondsToTime } from "utils";

const Stopwatch = forwardRef(({ className, defaultIsRunning }, ref) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(defaultIsRunning ?? true);
  const intervalId = useRef(null);

  useEffect(() => {
    if (isRunning) {
      intervalId.current = setInterval(() => setTime(prev => ++prev), 1000);
    } else {
      intervalId.current && clearInterval(intervalId.current)
    }
    return () => clearInterval(intervalId.current);
  }, [isRunning]);

  useImperativeHandle(ref, () => ({ start: () => setIsRunning(true), stop: () => setIsRunning(false) }));

  // function startAndStop() {
  //   setIsRunning(!isRunning);
  // };
  //
  // function reset() {
  //   setTime(0);
  // };

  const { formattedHours, formattedMinutes, formattedSeconds } = formatSecondsToTime(time);

  return (
    <div className={className}>
      {formattedHours}:{formattedMinutes}:{formattedSeconds}
    </div>
  );
});

export default memo(Stopwatch);