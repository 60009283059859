import { forwardRef } from 'react';
import { cn } from 'utils/';

import { Search, SkeletonText } from '@carbon/react';
import { useAutoSuggest } from 'hooks/useAutoSuggest';

const Autosuggest = ({
  value,
  delay,
  minLength,
  containerClass,
  inputClass,
  placeholder,
  source,
  onChange,
  renderInput,
  fancyMode = true,
  fancyModeValidate,
  invalid,
  invalidText,
  name,
}) => {
  const { inputProps, menuProps, containerRef } = useAutoSuggest({
    value,
    delay,
    minLength,
    fancyMode,
    fancyModeValidate,
    source,
    onChange,
  });

  return (
    <div ref={containerRef} className={cn('relative', containerClass)}>
      <Input
        render={renderInput}
        {...(name && { name })}
        {...(placeholder && { placeholder })}
        {...(inputClass && { inputClass })}
        {...(invalid && { invalid })}
        {...(invalidText && { invalidText })}
        {...inputProps}
      />

      <AutoSuggestMenu {...menuProps} />
    </div>
  );
};

const Input = forwardRef(({ render, ...restProps }, ref) => {
  if (typeof render === 'function') {
    return render({ ref, ...restProps });
  }

  return (
    <Search
      ref={ref}
      size="lg"
      labelText="Search"
      className={cn('border-none [&_input]:border-none', restProps?.inputClass)}
      {...restProps}
    />
  );
});

export const AutoSuggestMenu = ({
  listRef,
  isOpenMenu,
  isLoading,
  suggestions,
  selectedIndex,
  onSelectOption,
  onMouseOver,
}) => {
  if (!isOpenMenu) return null;

  return (
    <ul
      ref={listRef}
      className="absolute top-full left-0 w-full max-h-320 text-14 text-th-text-secondary bg-th-layer-02 shadow-shadowMenu z-999 overflow-y-auto"
    >
      {isLoading && (
        <li className="h-48 px-16 py-12 flex gap-8 items-center">
          <SkeletonText className="!w-150 m-0" />
        </li>
      )}

      {!isLoading && !suggestions.length && (
        <li className="h-48 px-16 py-12 flex items-center">No options</li>
      )}

      {!isLoading &&
        suggestions.map((suggestion, idx) => (
          <li
            key={idx}
            onClick={onSelectOption}
            onMouseOver={onMouseOver}
            className={cn('h-48 px-16 flex items-center py-12 cursor-pointer', (selectedIndex === idx && 'bg-th-layer-hover-02'))}
          >
            {suggestion?.text}
          </li>
        ))}
    </ul>
  );
};

export default Autosuggest;
