import { cn } from "utils";

const DrawerLayout = ({ children, className }) => {
  return (
    <div
      className={cn(
        "w-500 h-full flex flex-col bg-th-layer-01 overflow-auto",
        className
      )}
    >
      {children}
    </div>
  );
};

export default DrawerLayout;
