import { Cardboard } from "@carbon/pictograms-react";
import { cn } from "utils";

const EmptyState = ({
  className,
  primaryText = "No data to show",
  secondaryText = "Looks loke you dont haven't added anything here yet.",
}) => {
  return (
    <div className={cn("", className)}>
      <Cardboard />
      <p className="mb-4 text-18 font-semibold">{primaryText}</p>
      <p className="text-14">{secondaryText}</p>
    </div>
  );
};

export default EmptyState;
