import { useQuery } from "@tanstack/react-query";
import { api } from "services";
import { cn } from "utils";

import { useTaskCacheStore, useShallow } from "stores";
import DocumentMessage from "components/Message/DocumentMessage";
import ErrorMessage from "components/ErrorMessage";
import EmptyState from "components/EmptyState";
import { Loading } from "@carbon/react";

import { messageCardStyleMap } from "cnst";

const FilesChatTabs = ({ className }) => {
  const taskId = useTaskCacheStore(useShallow((state) => state.taskId));

  const queryKey = [`task-${taskId}-files`];
  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: () => api.get({ path: `/tasks/${taskId}/files` }),
    staleTime: 0,
    retry: false,
  });

  const showFiles = !isFetching && !isError && !!data.length;
  const showError = !isFetching && isError;
  const showEmpty = !isFetching && !isError && !data.length;

  return (
    <div className={cn("flex justify-between flex-col", className)}>
      <div className="sticky top-0 h-48 px-24 flex items-center text-14 font-semibold bg-th-layer-02">
        Files
      </div>

      <div className="flex-1 flex flex-col overflow-auto">
        {isFetching && (
          <Loading small withOverlay={false} className="mx-auto" />
        )}

        {showFiles && (
          <div className={cn("flex-1 flex flex-col px-24 py-12")}>
            {data.map((file) => (
              <DocumentMessage
                key={file?.id}
                styles={messageCardStyleMap.customer}
                data={file}
                className="w-full mb-8"
              />
            ))}
          </div>
        )}

        {showError && (
          <ErrorMessage
            className="mt-32 mx-auto"
            classContent="static"
            onClick={refetch}
          />
        )}
        {showEmpty && <EmptyState className="mt-32 mx-auto" />}
      </div>
    </div>
  );
};

export default FilesChatTabs;
