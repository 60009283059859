import { cn } from "utils";
import { useShallow, useTaskCacheStore, useVoiceCallApi } from "stores";

import { IconButton, Button, OverflowMenu } from "@carbon/react";
import TaskTypeSelect from "components/TaskTypeSelect";
import DueToSelect from "components/DueToSelect";
import AsyncCheckItem from "components/AsyncCheckItem";
import AssigneesController from "components/AssigneesController";
import StatusSelect from "components/StatusSelect";

import {
  Close,
  UserIdentification,
  Calendar,
  UserMultiple,
  TaskSettings,
  ListChecked,
  ChartAreaStepper,
  Information,
  CheckmarkFilled,
  Misuse,
  CircleDash, Phone,
} from "@carbon/react/icons";

const TaskMainInfo = ({ className, onClose }) => {
  const data = useTaskCacheStore(useShallow((state) => state.mainInfo));
  const taskId = useTaskCacheStore(useShallow((state) => state.taskId));
  const { driver, taskTypeOption, createdOn, dueDate, checkList, title } = data;

  let renderStatusIcon = null;
  switch (driver.status) {
    case "active":
      renderStatusIcon = (
        <CheckmarkFilled className="w-14 h-14 fill-th-support-success" />
      );
      break;
    case "inactive":
      renderStatusIcon = <Misuse className="w-14 h-14 fill-th-support-error" />;
      break;
    case "new":
      renderStatusIcon = <CircleDash className="w-14 h-14" />;
      break;
    default:
  }

  return (
    <div className={cn("overflow-y-auto", className)}>
      <div className="mb-12 pl-24 flex items-center">
        <div className="flex-1 text-14 font-semibold">
          #{taskId}
          {/* <span className="font-normal text-th-text-secondary">BOT</span> */}
        </div>

        <StatusSelect />

        <IconButton onClick={onClose} kind="ghost" label="Close">
          <Close />
        </IconButton>
      </div>

      {title ? (
        <div
          className="mb-12 px-24 text-20 font-semibold line-clamp-1"
          title={title}
        >
          {title}
        </div>
      ) : null}

      <div className="px-24 mb-16 flex">
        <div className="basis-120 flex items-center gap-6 text-12">
          <UserIdentification className="shrink-0" />
          Driver
          <DriverCaller />
        </div>

        <div className="flex-1 flex items-center gap-8 text-14 font-semibold">
          {renderStatusIcon}
          <span className="line-clamp-1" title={driver?.name}>
            {driver?.name}
          </span>
          {driver.notes && (
            <OverflowMenu
              flipped
              size="sm"
              ariaLabel="Notes"
              iconDescription="Notes"
              light
              menuOptionsClass="w-280 max-h-320"
              renderIcon={Information}
            >
              <h4 className="px-16 pt-16 mb-10 text-14 font-semibold">Notes</h4>
              <p className="w-full px-16 pb-16 text-14 break-words overflow-y-auto">
                {driver.notes}
              </p>
            </OverflowMenu>
          )}
        </div>

        <Button
          kind="ghost"
          {...(driver?.hosLink
            ? { href: driver.hosLink, as: "a", target: "_blank" }
            : { disabled: true })}
          size="sm"
          className="col-start-10 col-end-13"
          renderIcon={ChartAreaStepper}
        >
          Hos log
        </Button>
      </div>

      <div className="px-24 mb-24 flex">
        <div className="basis-120 flex items-center gap-8 text-12"></div>
        <div className="flex-1">
          <div className="text-12 text-th-text-secondary">Motor carrier</div>
          <div className="text-14">{driver.motorCarrier || "-"}</div>
        </div>
        <div className="flex-1 pl-16 border-l border-solid border-0 border-th-border-subtle-01">
          <div className="text-12 text-th-text-secondary">Trailer</div>
          <div className="text-14">{driver.trailerNumber || "-"}</div>
        </div>
        <div className="flex-1 pl-16 border-l border-solid border-0 border-th-border-subtle-01">
          <div className="text-12 text-th-text-secondary">Truck</div>
          <div className="text-14">{driver.truckNumber || "-"}</div>
        </div>
      </div>

      <ul className="mb-26 px-24">
        <li className="flex mb-24">
          <div className="basis-120 flex items-center gap-8 text-12">
            <TaskSettings className="shrink-0" /> Task type
          </div>
          <TaskTypeSelect
            className="flex-1"
            defaultOption={taskTypeOption}
            taskId={taskId}
          />
        </li>
        <li className="flex mb-24">
          <div className="basis-120 flex items-center gap-8 text-12">
            <Calendar className="shrink-0" /> Created on
          </div>
          <div className="flex-1 flex items-center text-12">{createdOn}</div>
        </li>
        <li className="flex mb-24">
          <div className="basis-120 flex items-center gap-8 text-12">
            <Calendar className="shrink-0" /> Due to
          </div>
          <DueToSelect className="flex-1" taskId={taskId} date={dueDate} />
        </li>
        <li className="flex mb-24">
          <div className="basis-120 flex gap-8 text-12">
            <UserMultiple className="shrink-0" /> Assigned
          </div>
          <AssigneesController className="flex-1" />
        </li>
      </ul>

      <div className="mb-16 px-24 flex items-center gap-8 text-14 font-semibold">
        <ListChecked className="shrink-0" />
        To do checklist
      </div>

      <ul className="px-24">
        {checkList?.map((item) => (
          <AsyncCheckItem key={item?.value} taskId={taskId} item={item} />
        ))}
      </ul>
    </div>
  );
};

function DriverCaller() {
  const { show, setOpen } = useVoiceCallApi(useShallow(({ show, setOpen }) => ({ show, setOpen })));
  const driver = useTaskCacheStore(useShallow((state) => state.mainInfo.driver));
  const isChatOpened = useTaskCacheStore(useShallow((state) => state.isChatOpened));

  if (show || !isChatOpened || !driver?.isPhoneExists) return null;

  return (
    <IconButton label="Calling" kind="ghost" size="sm" onClick={() => setOpen(driver)}>
      <Phone />
    </IconButton>
  )
}

export default TaskMainInfo;
