import { useId, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";
import { cn, getDateRange } from "utils";
import { toast } from "react-toastify";

import {
  Tag,
  Loading,
  DatePicker,
  DatePickerInput,
  IconButton,
} from "@carbon/react";

import { AddLarge, RequestQuote } from "@carbon/icons-react";

const DueToSelect = ({ taskId, date, className }) => {
  const datePickerId = useId();
  const [selectedDate, setSelectedDate] = useState(date ?? []);
  const datePickerRef = useRef();
  const buttonRef = useRef();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ values }) => api.patch({ path: `tasks/${taskId}`, values }),
    onSuccess: (_, { date }) => setSelectedDate(date),
    onError: (error) => toast.error(error),
  });

  const removeDate = () => handleMutation([]);
  const handleSetDate = (date) => handleMutation(date);

  function handleMutation(date) {
    const [dt] = date;
    const formData = new FormData();
    if (dt) {
      const day = `0${dt.getDate()}`.slice(-2);
      const month = `0${dt.getMonth() + 1}`.slice(-2);
      const year = `${dt.getFullYear()}`;
      formData.append("dueDate", `${year}-${month}-${day}T00:00:00`);
    } else {
      formData.append("dueDate", "");
    }
    mutate({ values: formData, date });
  }

  function openCalendar(e) {
    const { calendar } = datePickerRef.current || {};
    if (calendar) calendar.open(e, buttonRef.current);
  }

  const RenderIcon = selectedDate.length ? RequestQuote : AddLarge;
  return (
    <div className={cn("flex items-center gap-8", className)}>
      <DatePicker
        ref={datePickerRef}
        value={selectedDate}
        onChange={handleSetDate}
        datePickerType="single"
        className="!hidden"
      >
        <DatePickerInput id={datePickerId} labelText="" />
      </DatePicker>

      {selectedDate.length ? (
        <Tag disabled={isPending} onClose={removeDate} filter type="outline">
          {getDateRange(selectedDate)}
        </Tag>
      ) : (
        <span className="h-32 flex items-center text-14 text-th-text-placeholder">
          Set due date
        </span>
      )}

      {isPending ? (
        <Loading small withOverlay={false} />
      ) : (
        <IconButton
          ref={buttonRef}
          label="Due to"
          kind="ghost"
          size="sm"
          onClick={openCalendar}
        >
          <RenderIcon />
        </IconButton>
      )}
    </div>
  );
};

export default DueToSelect;
