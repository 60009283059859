import { Select, SelectItem } from "@carbon/react";

const CustomSelect = ({
  id,
  labelText,
  helperText,
  options,
  defaultOption,
  defaultDisabled = true,
  ...restProps
}) => {
  return (
    <Select
      id={id}
      labelText={labelText}
      helperText={helperText}
      className="invalid:text-th-text-placeholder"
      {...restProps}
    >
      {defaultOption && (
        <SelectItem
          disabled={defaultDisabled}
          value={defaultOption.value}
          text={defaultOption.text}
          {...defaultOption?.props}
        />
      )}
      {options.map((option) => (
        <SelectItem
          key={option.value}
          value={option.value}
          text={option.text}
          {...option?.props}
        />
      ))}
    </Select>
  );
};

export default CustomSelect;
