import { Warning_02 as Warning } from "@carbon/pictograms-react";
import { Link } from "@carbon/react";
import { cn } from "utils";

const ErrorMessage = ({ className, classContent, onClick }) => {
  return (
    <div className={cn("relative", className)}>
      <div
        className={cn("absolute top-1/4 left-100 flex flex-col", classContent)}
      >
        <Warning className="mb-24" />
        <p className="mb-4 text-18 text-th-text-primary">
          Sorry something went wrong.
        </p>
        <p className="mb-24 text-14 text-th-text-secondary">
          Is currently unable to handle this request.
        </p>
        <p className="text-14 text-th-text-secondary">
          Try to{" "}
          <Link onClick={onClick} className="cursor-pointer">
            refresh
          </Link>{" "}
          this page.
        </p>
      </div>
    </div>
  );
};

export default ErrorMessage;
