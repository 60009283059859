import { Loading } from "@carbon/react";
import { useTaskViewModel } from "./useTaskViewModel";
import { useQueryClient } from "@tanstack/react-query";

import ChatTabs from "./ChatTabs";
import TaskMainInfo from "./TaskMainInfo";
import { useEffect } from "react";
import ErrorMessage from "components/ErrorMessage";

const Task = ({ taskId, queryKey = null, onClose }) => {
  const queryClient = useQueryClient();
  const { isFetching, isError, refetch } = useTaskViewModel({ id: taskId });

  useEffect(() => {
    return () => {
      if (queryKey) {
        queryClient.invalidateQueries({ queryKey });
      }
    };
  }, []);

  if (isFetching) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <Loading withOverlay={false} />
      </div>
    );
  }
  if (isError) {
    return <ErrorMessage className="flex-1" onClick={refetch} />;
  }

  return (
    <>
      <TaskMainInfo onClose={onClose} className="flex-1" />
      <ChatTabs className="flex-1" />
    </>
  );
};

export default Task;
