import { useRooms, useShallow } from "stores";

const TaskCount = ({ roomId, taskColId }) => {
  const count = useRooms(
    useShallow((store) => store.totalByTasks?.[roomId]?.[taskColId])
  );
  const renderCount = typeof count === "number" ? `(${count})` : null;

  return <>{renderCount}</>;
};

export default TaskCount;
