import { TextInputSkeleton, TextAreaSkeleton } from "@carbon/react";
import { cn } from "utils";

const SkeletonForm = ({ className }) => {
  return (
    <div className={cn("grid grid-cols-12 gap-16", className)}>
      <TextInputSkeleton className="col-start-1 col-end-7" />
      <TextInputSkeleton className="col-start-7 col-end-13" />
      <TextInputSkeleton className="col-span-full" />
      <TextAreaSkeleton className="col-span-full" />
    </div>
  );
};

export default SkeletonForm;
