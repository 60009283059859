import { useEffect, useState } from 'react';
import { api } from 'services';
import { cn, convertSecToMs, parseIsoString } from 'utils';
import { getFullNameBaseEntity } from 'helpers';
import { avaStylesByRoleMap, fileTypeMap } from 'cnst';
import MessageImage from './MessageImage';
import MessageAudio from './MessageAudio';
import MessageFile from './MessageFile';
import Avatar from 'components/Avatar';
import MessageSkeleton from './MessageSkeleton';

function DocumentMessage ({ data, styles, className, infoPosition = 'bottom' }) {
  const { content: link, createdAt, telegramTimestamp, user, isEdited } = data || {};
  const [document, setDocument] = useState(null);

  useEffect(() => {
    api
      .get({
        path: link,
        useBaseUrl: false,
        requestOptions: {
          responseType: 'blob',
        },
      })
      .then((blob) => ({ type: blob.type, link: URL.createObjectURL(blob) }))
      .then((data) => setDocument(data))
      .catch((e) => console.warn(e));
  }, []);

  const docType = fileTypeMap[document?.type];
  let RenderDoc;

  switch (docType) {
    case 'image':
      RenderDoc = MessageImage;
      break;
    case 'audio':
      RenderDoc = MessageAudio;
      break;
    default:
      RenderDoc = MessageFile;
  }

  const fullName = getFullNameBaseEntity(user);
  const timestampOrCreatedAt = telegramTimestamp ? convertSecToMs(telegramTimestamp) : createdAt;
  const messageSendingTime = parseIsoString(timestampOrCreatedAt);

  return <div className={cn('min-h-96 flex flex-col justify-between', styles.card, className)}>
    {infoPosition === 'top' && (
      <div className="flex items-center justify-between gap-8 text-12 text-th-text-secondary">
        <div className="flex items-center gap-4">
          <Avatar
            className={cn('w-24 h-24', avaStylesByRoleMap[user?.role])}
            name={fullName}
          />
          {fullName}
        </div>
        {messageSendingTime}
      </div>
    )}

    {document && <RenderDoc data={{ file: data?.file, link: document?.link }} styles={styles} />}
    {!document && <MessageSkeleton styles={styles} />}

    {data?.caption &&
      <p className="my-6 text-14 break-words leading-130" dangerouslySetInnerHTML={{ __html: data.caption }} />}

    {infoPosition === 'bottom' && (
      <div
        className={cn('flex items-center justify-between gap-8 text-12', styles.secondaryColor)}
      >
        <span>{messageSendingTime}</span>
        {user && <span>{fullName}</span>}
        {isEdited && <span className="flex-1 text-right">(edited)</span>}
      </div>
    )}
  </div>;
}

export default DocumentMessage;
