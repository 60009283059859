import { forwardRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { cn } from "utils";

const TextArea = ({ maxRows = 10, minRows = 1, className, ...rest }, ref) => {
  return (
    <TextareaAutosize
      className={cn(
        "w-full py-12 px-16 text-16 text-th-text-primary caret-th-text-primary placeholder:text-th-text-placeholder border-2 border-solid border-transparent focus:border-th-focus",
        className
      )}
      maxRows={maxRows}
      minRows={minRows}
      {...(ref ? { ref } : {})}
      {...rest}
    />
  );
};

export default forwardRef(TextArea);
