import { useMemo, useState } from "react";
import { Search } from "@carbon/react";
import { cn } from "utils";

const SearchableMenu = ({
  isOpen,
  className,
  listItems,
  renderItem,
  onValidate = () => {},
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const searchResults = useMemo(() => {
    return listItems.filter((listItem) => onValidate({ searchTerm, listItem }));
  }, [searchTerm, listItems]);

  function handleChange(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      {isOpen && (
        <ul className={cn("w-full text-14 text-th-text-secondary", className)}>
          <Search
            size="md"
            value={searchTerm}
            placeholder="Search input text"
            className={cn("sticky top-0 [&_input]:border-th-border-subtle-02")}
            // border-none [&_input]:border-none
            onChange={handleChange}
          />
          {searchResults.map((listItem) => (
            <>{renderItem(listItem)}</>
          ))}
        </ul>
      )}
    </>
  );
};

// const Item = () => {
//   return <></>;
// };
// SearchableMenu.Item = Item;

export default SearchableMenu;
