import { useEffect, useRef } from 'react';

const useScrollToBottomOnMessageChange = (messages) => {
  const anchorMessageRef = useRef();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (anchorMessageRef.current) {

      if (!isMountedRef.current) {
        anchorMessageRef.current.scrollIntoView({ block: 'end' });
        isMountedRef.current = true;
        return;
      }

      const lastMessage = messages?.[messages.length - 1];
      if (lastMessage && lastMessage.from === 'worker') {
        anchorMessageRef.current.scrollIntoView({ block: 'end' });
      }
    }
  }, [messages]);

  return { anchorMessageRef };
};

export default useScrollToBottomOnMessageChange;
