import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDrawerStore, useShallow } from "stores";
import useLockedBody from "hooks/useLockedbody";

import DrawerLayout from "layouts/DrawerLayout";
import RoomFilter from "views/Room/Forms/FilterForm";
import McFilter from "views/MotorCarriers/FilterForm";
import DriverFilter from "views/Drivers/FilterForm";
import UpdateRoom from "views/AdminSettings/Forms/UpdateRoom";
import UpdateEmployee from "views/AdminSettings/Forms/UpdateEmployee";
import UpdateBot from "views/AdminSettings/Forms/UpdateBot";
import Task from "views/Room/Task/Task";
import UpdateMotorCarrier from "views/MotorCarriers/UpdateMotorCarrier";
import UpdateDriver from "views/Drivers/UpdateDriver";
import CreateNewTask from "views/Room/Forms/CreateNewTask";

const overlayMotion = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const containerMotion = {
  hidden: {
    opacity: 0,
    // x: "100%",
  },
  visible: {
    opacity: 1,
    // x: 0,
  },
};

const RightDrawer = () => {
  const { show, setClose } = useDrawerStore(useShallow((store) => store));
  const renderKey = show?.key;
  const renderProps = show?.props;
  const isOpen = Boolean(show);
  const handleCloseDrawer = setClose;

  useLockedBody(isOpen, document.body);

  let renderView = null;
  switch (renderKey) {
    case "roomFilter":
      renderView = (
        <DrawerLayout className="w-350">
          <RoomFilter {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "adminUpdateRoom":
      renderView = (
        <DrawerLayout>
          <UpdateRoom {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "adminUpdateBot":
      renderView = (
        <DrawerLayout>
          <UpdateBot {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "adminUpdateEmployee":
      renderView = (
        <DrawerLayout>
          <UpdateEmployee {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "updateMotorCarrier":
      renderView = (
        <DrawerLayout>
          <UpdateMotorCarrier {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "updateDriver":
      renderView = (
        <DrawerLayout>
          <UpdateDriver {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "mcFilter":
      renderView = (
        <DrawerLayout className="w-350">
          <McFilter {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "driverFilter":
      renderView = (
        <DrawerLayout className="w-350">
          <DriverFilter {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "task":
      renderView = (
        <DrawerLayout className="w-942 flex-row">
          <Task {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    case "newTask":
      renderView = (
        <DrawerLayout>
          <CreateNewTask {...renderProps} onClose={handleCloseDrawer} />
        </DrawerLayout>
      );
      break;
    default:
      renderView = null;
  }

  return ReactDOM.createPortal(
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            onClick={handleCloseDrawer}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayMotion}
            className="fixed top-0 left-0 right-0 bottom-0 bg-th-overlay z-200"
          />

          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={containerMotion}
            transition={{ ease: "easeInOut", duration: 0.3 }}
            className="fixed top-0 right-0 min-h-screen h-full z-250"
          >
            {renderView}
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default RightDrawer;
