import { useMemo, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { api } from "services";
import { cn } from "utils";
import { toast } from "react-toastify";

import UserTag from "./UserTag";
import { Loading, OverflowMenu, TagSkeleton } from "@carbon/react";
import SearchableMenu from "./SearchableMenu";
import { AddLarge } from "@carbon/icons-react";
import Avatar from "./Avatar";
import { avaStylesByRoleMap } from "cnst";
import { useShallow, useTaskCacheStore } from "stores";

const AssigneesController = ({ className }) => {
  const { taskId, assignees, setAssignees, setStatus, setChatOpened } =
    useTaskCacheStore(
      useShallow((state) => ({
        setChatOpened: state.setChatOpened,
        taskId: state.taskId,
        assignees: state.assignees,
        setAssignees: state.setAssignees,
        setStatus: state.setStatus,
      }))
    );
  const menuRef = useRef(null);
  const ownerExist = Boolean(assignees.length);

  const { data: users, isFetching } = useQuery({
    queryFn: () => api.get({ path: "/users", role: "protected" }),
    queryKey: [],
    staleTime: 0,
    retry: false,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: ({ values }) => api.patch({ path: `tasks/${taskId}`, values }),
    onSuccess: (_, { newAssignees }) => {
      !ownerExist && setStatus("inProcess");
      !ownerExist && setChatOpened(true);
      setAssignees(newAssignees);
    },
    onError: (error) => toast.error(error),
  });

  const handleAdd = (item) => () => {
    const formData = new FormData();
    const newItem = { ...item, ...(ownerExist ? {} : { owner: true }) };
    const newAssignees = [...assignees, newItem];

    for (const item of newAssignees) {
      formData.append("assignees[]", item.id);
    }
    mutate({ values: formData, newAssignees });
  };
  const handleRemove = (itemId) => () => {
    const formData = new FormData();
    const newAssignees = assignees.filter((assigned) => assigned.id !== itemId);

    for (const item of newAssignees) {
      formData.append("assignees[]", item.id);
    }
    mutate({ values: formData, newAssignees });
  };
  function handleValidate({ searchTerm, listItem }) {
    const fullName = `${listItem.firstName} ${listItem.lastName}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  }

  const filteredUsers = useMemo(() => {
    const usersCopy = users?.entities ?? [];
    return usersCopy.filter(
      (user) => !assignees.find(({ id }) => id === user.id)
    );
  }, [users, assignees]);

  if (isFetching) return <TagSkeleton size="sm" />;

  return (
    <div className={cn("flex items-center flex-wrap gap-6", className)}>
      {assignees.map((assigned) => {
        const fullName = `${assigned.firstName} ${assigned.lastName}`;
        return (
          <UserTag
            key={assigned?.id}
            disabled={isPending}
            role={assigned?.role}
            readOnly={assigned?.owner}
            fullName={fullName}
            className="shrink-0 my-4"
            onRemove={handleRemove(assigned.id)}
          />
        );
      })}

      {!assignees.length ? (
        <span className="text-14 text-th-text-placeholder">no users</span>
      ) : null}

      {isPending && <Loading small withOverlay={false} />}

      {Boolean(filteredUsers.length) && !isPending && (
        <OverflowMenu
          ref={menuRef}
          flipped
          size="sm"
          light
          menuOptionsClass="w-250 max-h-320 overflow-y-auto"
          renderIcon={AddLarge}
        >
          <SearchableMenu
            isOpen
            listItems={filteredUsers}
            onValidate={handleValidate}
            renderItem={(item) => {
              const fullName = `${item.firstName} ${item.lastName}`;
              return (
                <li
                  key={item.id}
                  onClick={handleAdd(item)}
                  className="h-48 px-16 flex items-center gap-8 betterhover:hover:bg-th-layer-hover-02 cursor-pointer"
                >
                  <Avatar
                    name={fullName}
                    className={cn("w-32 h-32", avaStylesByRoleMap[item.role])}
                  />
                  {fullName}
                </li>
              );
            }}
          />
        </OverflowMenu>
      )}
    </div>
  );
};

export default AssigneesController;
