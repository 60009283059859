import { cn } from "utils";
import Avatar from "./Avatar";
import { Close } from "@carbon/icons-react";
import { avaStylesByRoleMap } from "cnst";

const UserTag = ({
  readOnly,
  disabled,
  fullName,
  className,
  role,
  onRemove,
}) => {
  const avatarClass = avaStylesByRoleMap[role];
  return (
    <div
      className={cn(
        "flex items-center gap-2 pr-4 text-12 text-th-text-primary bg-th-tag-background-gray rounded-100",
        readOnly && "pr-8 border border-solid border-th-border-interactive",
        disabled && "pr-8",
        className
      )}
    >
      <Avatar name={fullName} className={cn("w-24 h-24", avatarClass)} />
      {fullName}
      {!(readOnly || disabled) && (
        <Close className="cursor-pointer" onClick={onRemove} />
      )}
    </div>
  );
};

export default UserTag;
