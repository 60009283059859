import { createElement } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "services";

import { cn, sanitizeObject } from "utils";
import withFetchFormData from "hoc/withFetchFormData";
import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";

import { newTaskForm } from "./formData";
import { getFullNameBaseEntity } from "helpers";

const getTaskOptions = (data) => {
  const [users] = data || [];
  const usersOptions = users?.entities.map((user) => ({
    value: user.id,
    text: getFullNameBaseEntity(user),
  }));
  return { key: "assignees", options: usersOptions || {} };
};

const CreateNewTask = ({ roomId, fetchedData, className, onClose }) => {
  const { defaultValues, formInputs, schema } = newTaskForm;

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api.post({ path: "/tasks", values }),
    onSuccess: () => {
      onClose();
    },
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues, roomId },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => {
    values = sanitizeObject(values);
    const formData = new FormData();

    for (const key in values) {
      const val = values[key];

      if (key === "assignees") {
        val?.forEach((item) => {
          formData.append("assignees[]", item.value);
        });
      } else if (key === "dueDate") {
        let [dateInString] = val;
        dateInString = dateInString ? new Date(dateInString).toISOString() : "";
        formData.append(key, dateInString);
      } else if (key === "driver") {
        formData.append("driverId", val?.value);
      } else {
        formData.append(key, val);
      }
    }

    console.log(values);
    mutate(formData);
  });

  if (fetchedData.options.length) {
    formInputs[fetchedData.key].inputProps.items = fetchedData.options;
  }

  return (
    <>
      <SidebarHeading onClose={onClose}>Create new task</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 grid grid-cols-12 gap-16">
          {Object.values(formInputs).map(
            ({ Component, name, inputProps, withValue, change }) => (
              <Controller
                key={name}
                name={name}
                control={control}
                render={({ field }) => {
                  const { ref, value, onChange, ...restField } = field;
                  const errorMessage = errors?.[name]?.message;

                  return createElement(Component, {
                    id: name,
                    light: true,
                    invalid: Boolean(errorMessage),
                    invalidText: errorMessage,
                    ...inputProps,
                    ...restField,
                    ...(withValue ? withValue(value) : { value }),
                    ...(change ? change(onChange) : { onChange }),
                  });
                }}
              />
            )
          )}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText="Save"
          onClose={onClose}
          isPending={isPending}
        />
      </form>
    </>
  );
};

export default withFetchFormData(CreateNewTask, {
  paths: ["/users"],
  transformData: getTaskOptions,
});
