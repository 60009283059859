import { createElement } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "services";

import { cn, createObjectBaseMatchVal } from "utils";
import { toast } from "react-toastify";
import withFetchFormData from "hoc/withFetchFormData";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateBotSchema } from "schema";

import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";
import { botForm } from "./formData";

const getRoomOptions = (data) => {
  const [roomOptions] = data || [];
  const options = roomOptions?.entities.map((room) => ({
    value: room.id,
    text: room.name,
  }));

  return { key: "roomId", options: options || [] };
};

const UpdateBot = ({
  operation,
  formValues,
  fetchedData,
  method,
  path,
  queryKey,
  heading,
  className,
  onClose,
  primaryButtonText,
}) => {
  const queryClient = useQueryClient();
  const { defaultValues, formInputs } = botForm;

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api[method]({ path, values }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => toast.error(error),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: createObjectBaseMatchVal(defaultValues, formValues),
    resolver: yupResolver(updateBotSchema),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => mutate(values));

  if (fetchedData.options.length) {
    formInputs[fetchedData.key].inputProps.options = fetchedData.options;
  }

  return (
    <>
      <SidebarHeading onClose={onClose}>{heading}</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 grid grid-cols-12 gap-16">
          {Object.values(formInputs).map(({ Component, name, inputProps }) => (
            <Controller
              key={name}
              name={name}
              control={control}
              render={({ field }) => {
                const { ref, ...restField } = field;
                const errorMessage = errors?.[name]?.message;
                const isTokenOrRoomId = name === "token" || name === "roomId";
                const isDisabledForUpdateOperation =
                  operation === "update" && isTokenOrRoomId;

                return createElement(Component, {
                  id: name,
                  light: true,
                  invalid: Boolean(errorMessage),
                  invalidText: errorMessage,
                  ...inputProps,
                  ...restField,
                  ...(isDisabledForUpdateOperation ? { disabled: true } : {}),
                });
              }}
            />
          ))}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText={primaryButtonText}
          onClose={onClose}
          isPending={isPending}
        />
      </form>
    </>
  );
};

export default withFetchFormData(UpdateBot, {
  paths: ["/rooms"],
  transformData: getRoomOptions,
});
