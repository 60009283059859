import { useForm, Controller } from "react-hook-form";
import { cn, createObjectBaseMatchVal } from "utils";
import withFetchFormData from "hoc/withFetchFormData";

import { FilterableMultiSelect } from "@carbon/react";
import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";
import CustomSelect from "components/CustomSelect";
import { statusOptions } from "data/options";
import { getFullNameBaseEntity } from "helpers";
import { filterMCDefaultValues } from "cnst";

const transformData = (data) => {
  const [mcs] = data || [];

  const mcOptions = mcs?.entities.map((mc) => ({
    value: mc.id,
    text: getFullNameBaseEntity(mc),
  }));

  return { mcOptions };
};

const FilterForm = ({
  fetchedData,
  formValues,
  onClose,
  onFilters,
  className,
}) => {
  const { mcOptions = [] } = fetchedData || {};

  const { formState, control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: createObjectBaseMatchVal(filterMCDefaultValues, formValues),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => {
    onFilters(values);
    onClose();
  });

  return (
    <>
      <SidebarHeading onClose={onClose}>Filter</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 flex flex-col gap-16">
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              const { ref, value, onChange, ...restField } = field;
              const errorMessage = errors?.taskType?.message;
              return (
                <FilterableMultiSelect
                  id="mc"
                  light
                  titleText="Motor Carrier"
                  initialSelectedItems={value}
                  items={mcOptions}
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  itemToString={(item) => (item ? item.text : "")}
                  onChange={({ selectedItems }) => onChange(selectedItems)}
                  {...restField}
                />
              );
            }}
          />

          <Controller
            name="status"
            control={control}
            render={({ field }) => {
              const { ref, ...restField } = field;
              const errorMessage = errors?.status?.message;
              return (
                <CustomSelect
                  id="status"
                  light
                  defaultDisabled={false}
                  defaultOption={{
                    value: "",
                    text: "Select Status",
                    props: { disabled: true },
                  }}
                  labelText="Status"
                  options={statusOptions}
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  {...restField}
                />
              );
            }}
          />
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText="Apply"
          onClose={onClose}
          isPending={false}
        />
      </form>
    </>
  );
};

export default withFetchFormData(FilterForm, {
  paths: ["/clients"],
  transformData,
});
