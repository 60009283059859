import { useForm, Controller } from "react-hook-form";
import { cn, createObjectBaseMatchVal } from "utils";
import withFetchFormData from "hoc/withFetchFormData";

import {
  FilterableMultiSelect,
  DatePicker,
  DatePickerInput,
} from "@carbon/react";
import SidebarHeading from "components/SidebarHeading";
import FormGroupBtn from "components/FormGroupBtn";
import CustomSelect from "components/CustomSelect";
import { jobTypeOptions, jobTypeDefaultOption, filterRoomDefaultValues } from "cnst";
import { getFullNameBaseEntity } from "helpers";

const transformData = (data) => {
  const [drivers, users] = data || [];

  const driversOptions = drivers?.entities.map((driver) => ({
    value: driver.id,
    text: getFullNameBaseEntity(driver),
  }));
  const usersOptions = users?.entities.map((user) => ({
    value: user.id,
    text: getFullNameBaseEntity(user),
  }));

  return { driversOptions, usersOptions };
};

const FilterForm = ({
  fetchedData,
  formValues,
  onClose,
  onFilters,
  className,
}) => {
  const { driversOptions = [], usersOptions = [] } = fetchedData || {};

  const { formState, control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: createObjectBaseMatchVal(
      filterRoomDefaultValues,
      formValues
    ),
  });
  const { errors } = formState;

  const handleSubmitForm = handleSubmit((values) => {
    onFilters(values);
    onClose();
  });

  return (
    <>
      <SidebarHeading onClose={onClose}>Filter</SidebarHeading>

      <form className={cn("", className)} onSubmit={handleSubmitForm}>
        <div className="px-16 flex flex-col gap-16">
          <Controller
            name="drivers"
            control={control}
            render={({ field }) => {
              const { ref, value, onChange, ...restField } = field;
              const errorMessage = errors?.taskType?.message;
              return (
                <FilterableMultiSelect
                  id="drivers"
                  light
                  titleText="Driver"
                  initialSelectedItems={value}
                  items={driversOptions}
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  itemToString={(item) => (item ? item.text : "")}
                  onChange={({ selectedItems }) => onChange(selectedItems)}
                  {...restField}
                />
              );
            }}
          />

          <Controller
            name="jobType"
            control={control}
            render={({ field }) => {
              const { ref, ...restField } = field;
              const errorMessage = errors?.jobType?.message;
              return (
                <CustomSelect
                  id="jobType"
                  light
                  defaultDisabled={false}
                  defaultOption={jobTypeDefaultOption}
                  labelText="Task Type"
                  options={jobTypeOptions}
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  {...restField}
                />
              );
            }}
          />

          <Controller
            name="users"
            control={control}
            render={({ field }) => {
              const { ref, value, onChange, ...restField } = field;
              const errorMessage = errors?.assigned?.message;
              return (
                <FilterableMultiSelect
                  id="users"
                  light
                  className="col-span-full"
                  titleText="Assigned"
                  initialSelectedItems={value}
                  items={usersOptions}
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  itemToString={(item) => (item ? item.text : "")}
                  onChange={({ selectedItems }) => onChange(selectedItems)}
                  {...restField}
                />
              );
            }}
          />

          <Controller
            name="rangeDate"
            control={control}
            render={({ field }) => {
              const { ref, ...restField } = field;
              const errorMessage = errors?.rangeDate?.message;
              return (
                <DatePicker
                  light
                  datePickerType="range"
                  invalid={Boolean(errorMessage)}
                  invalidText={errorMessage}
                  {...restField}
                >
                  <DatePickerInput
                    id="date-picker-input-id-start"
                    autoComplete="off"
                    placeholder="mm/dd/yyyy"
                    labelText="Start date"
                    size="lg"
                  />
                  <DatePickerInput
                    id="date-picker-input-id-end"
                    autoComplete="off"
                    placeholder="mm/dd/yyyy"
                    labelText="End date"
                    size="lg"
                  />
                </DatePicker>
              );
            }}
          />

          {/* <Controller
              name="createdTill"
              control={control}
              render={({ field }) => {
                const { ref, onChange, ...restField } = field;
                const errorMessage = errors?.createdTill?.message;
                return (
                  <DatePicker
                    light
                    datePickerType="single"
                    invalid={Boolean(errorMessage)}
                    invalidText={errorMessage}
                    onChange={(value) => onChange(value[0])}
                    {...restField}
                  >
                    <DatePickerInput
                      id="date-picker-input-id-createdTill"
                      placeholder="mm/dd/yyyy"
                      labelText="End date"
                      size="lg"
                    />
                  </DatePicker>
                );
              }}
            /> */}
        </div>

        <FormGroupBtn
          secondaryButtonText="Cancel"
          primaryButtonText="Apply"
          onClose={onClose}
          isPending={false}
        />
      </form>
    </>
  );
};

export default withFetchFormData(FilterForm, {
  paths: ["/drivers", "/users"],
  transformData,
});
// export default FilterForm;
