import { useEffect, useState, useRef } from 'react';
import { formatSecondsToTime } from 'utils';

const useAudio = (url) => {
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(url));
  const intervalRef = useRef();
  const { formattedMinutes, formattedSeconds } = formatSecondsToTime(progress === 0 ? duration : progress);
  const displayTime = `${formattedMinutes}:${formattedSeconds} `;
  

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setProgress(audio.currentTime);
    };
    const handleLoadedMetaData = (e) => {
      setDuration(e.target.duration);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetaData);

    return () => {
      audio.pause();
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetaData);
      clearInterval(intervalRef.current);
      setDuration(null);
    };
  }, [url]);

  const toggle = () => {
    setIsPlaying((prev) => {
      if (!prev) {
        audioRef.current.play();
        startTimer();
      } else {
        audioRef.current.pause();
        clearInterval(intervalRef.current);
      }
      return !prev;
    });
  };

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        setProgress(0);
        clearInterval(intervalRef.current);
      }
    }, 100);
  };

  const changeProgress = (e) => {
    const value = e.target.value / 1000;
    setIsPlaying(false);
    setProgress(value);
    audioRef.current.pause();
    audioRef.current.currentTime = value;
  };

  return {
    isPlaying,
    displayTime,
    progressInMs: progress * 1000,
    durationInMs: duration * 1000,
    toggle,
    changeProgress,
  };
};

export default useAudio;

// const useAudio1 = (url) => {
//   const [progress, setProgress] = useState(0);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const audioRef = useRef(new Audio(url));
//   const intervalRef = useRef();
//
//   const { duration } = audioRef.current;
//
//   useEffect(() => {
//     if (isPlaying) {
//       audioRef.current.play();
//       startTimer();
//     } else {
//       audioRef.current.pause();
//       clearInterval(intervalRef.current);
//     }
//   }, [isPlaying]);
//
//   useEffect(() => {
//     return () => {
//       audioRef.current.pause();
//       clearInterval(intervalRef.current);
//     };
//   }, []);
//
//   const startTimer = () => {
//     clearInterval(intervalRef.current);
//
//     intervalRef.current = setInterval(() => {
//       if (audioRef.current.ended) {
//         setIsPlaying(false);
//         setProgress(0);
//         clearInterval(intervalRef.current);
//       } else {
//         setProgress(audioRef.current.currentTime);
//       }
//     }, [100]);
//   };
//
//   function toggle() {
//     setIsPlaying((prev) => !prev);
//   }
//
//   function changeProgress(e) {
//     const value = e.target.value;
//     if (isPlaying) setIsPlaying(false);
//     if (intervalRef.current) clearInterval(intervalRef.current);
//     setProgress(value / 1000);
//     audioRef.current.currentTime = value / 1000;
//   }
//
//   return { isPlaying, toggle, progress, duration, changeProgress };
// };
