import { useMemo, useState } from "react";
import { cn } from "utils";
import { useTaskCacheStore, useShallow } from "stores";

import { Button } from "@carbon/react";
import LiveChatTab from "./LiveChatTab";
import HistoryChatTab from "./HistoryChatTab";
import FilesChatTabs from "./FilesChatTabs";

import { IbmWatsonTextToSpeech, FolderMoveTo } from "@carbon/react/icons";
import { TelegramIcon } from "assets/icons";

const btnActive =
  "bg-th-layer-02 after:content-[''] after:absolute after:top-0 after:right-0 after:h-full after:w-3 after:bg-th-focus";

const tabMapping = {
  worker: {
    tabPanels: [HistoryChatTab, FilesChatTabs],
    tabList: [IbmWatsonTextToSpeech, FolderMoveTo],
  },
  customer: {
    tabPanels: [LiveChatTab, HistoryChatTab, FilesChatTabs],
    tabList: [TelegramIcon, IbmWatsonTextToSpeech, FolderMoveTo],
  },
};

const ChatTabs = ({ className }) => {
  const createdBy = useTaskCacheStore(useShallow((state) => state.createdBy));
  const [tabIndex, setTabIndex] = useState(0);
  const { tabPanels, tabList } = useMemo(() => tabMapping[createdBy], []);

  const TabPanel = tabPanels[tabIndex];

  return (
    <div className={cn("flex", className)}>
      <TabPanel className="flex-1 bg-th-layer-02" />

      <div className="flex flex-col w-52">
        {tabList.map((Icon, index) => (
          <Button
            key={index}
            onClick={() => setTabIndex(index)}
            kind="ghost"
            className={cn(
              "w-full flex justify-center items-center",
              tabIndex === index && btnActive
            )}
          >
            <Icon className="w-16 h-16" />
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ChatTabs;
