import { SkeletonIcon, SkeletonText } from '@carbon/react';
import { cn } from 'utils';

function MessageSkeleton ({ className }) {
  return (
    <div className={cn('flex gap-8', className)}>
      <SkeletonIcon className="shrink-0 w-48 h-48" />
      <div className="flex-1">
        <SkeletonText />
        <SkeletonText width="50%" />
      </div>
    </div>
  );
}

export default MessageSkeleton;
