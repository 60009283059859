import { useQueries } from '@tanstack/react-query';
import { api } from 'services';
import SkeletonForm from 'components/SkeletonForm';
import ErrorMessage from 'components/ErrorMessage';

const withFetchFormData = (WrappedComponent, config) => (props) => {
  const { transformData, paths } = config;

  const results = useQueries({
    queries: paths.map((path) => {
      return {
        queryKey: [path],
        queryFn: () => api.get({ path, role: 'protected' }),
        retry: false,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
        isFetching: results.some((result) => result.isFetching),
        isError: results.some((result) => result.isError),
        refetchAll: () => results.forEach(result => result.refetch()),
      };
    },
  });

  if (results.isFetching) {
    return <SkeletonForm className="p-16" />;
  }

  if (results.isError) {
    return <ErrorMessage classContent="static mt-50 ml-50" onClick={results.refetchAll} />;
  }

  const fetchedData = transformData(results.data);
  return <WrappedComponent fetchedData={fetchedData} {...props} />;
};

export default withFetchFormData;
