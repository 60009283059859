import { api } from 'services';
import sound from '../files/notification-sound.MP3';
import { getDateRange, getUtcDate } from 'utils';
import { fileTypeMap, jobTagTypeMap, jobTitleMap } from 'cnst';

export const getErrorMessage = ({ response, path, method }) => {
  const ERROR_MESSAGE = 'Something went wrong. Please try again';
  if (!response) return ERROR_MESSAGE;
  const { errors, msg } = response?.data;
  if (errors) return errors;
  if (msg) return msg;
  const errorMessages = {
    get: {},
    post: {
      '/auth/sign-in': { 400: 'Incorrect email or password. Please try again' },
    },
    patch: {},
    delete: {},
  };
  return errorMessages?.[method]?.[path]?.[response?.status] ?? ERROR_MESSAGE;
};

export function getFullNameBaseEntity (entity) {
  const firstName = entity?.firstName || entity?.telegramFirstName || entity?.name || '';
  const lastName = entity?.lastName || entity?.telegramLastName || '';

  return `${firstName}${lastName && ` ${lastName}`}`;
}

export async function autosuggestSource (str) {
  const params = extractFirstAndLastNameParams(str);
  const path = `/drivers?${params}`;

  const res = await api.get({ path });
  return getOptionsByEntityName(res?.entities || []);
}

export function getOptionsByEntityName (entities) {
  if (!entities && typeof entities !== 'object') return [];
  return entities.map((v) => ({
    text: getFullNameBaseEntity(v),
    value: v?.id,
  }));
}

function extractFirstAndLastNameParams (string) {
  const [firstName, lastName] = string.split(' ').slice(0, 2);
  const firstNameQuery = `firstName=${firstName}`;
  const lastNameQuery = lastName ? `&lastName=${lastName}` : '';
  return `${firstNameQuery}${lastNameQuery}`;
}

function extractNameParams (string) {
  return `name=${string}`;
}

const paramFunctions = { 'firstLastName': extractFirstAndLastNameParams, 'name': extractNameParams };

export function autoSuggestSource ({ basePath, paramKey, callBack }) {
  return async (string) => {
    const params = paramFunctions[paramKey]?.(string) || '';
    const path = `${basePath}?${params}`;

    const response = await api.get({ path });

    return callBack(response);
  };
}

export function generateRoomQueryParams (filters, search) {
  const { drivers, jobType, users, rangeDate } = filters || {};
  let queryParams = '';

  if (drivers?.length) {
    for (const driver of drivers) {
      queryParams += `&driverIds=${driver?.value}`;
    }
  }

  queryParams += jobType ? `&jobType=${jobType}` : '';

  if (users?.length) {
    for (const user of users) {
      queryParams += `&userIds=${user?.value}`;
    }
  }

  const [start, end] = rangeDate || [];
  if (start) {
    queryParams += `&createdFrom=${getUtcDate(start)}`;
  }
  if (end) {
    queryParams += `&createdTill=${getUtcDate(end)}`;
  }

  const searchStr = search ? `&${search.param}=${search.value}` : '';
  return searchStr + queryParams;
}

export const getRoomTags = (filters) => {
  const { drivers, jobType, users, rangeDate } = filters || {};
  const tags = [];
  if (drivers?.length) {
    tags.push(...drivers.map((driver) => ({ ...driver, isArray: 'drivers' })));
  }

  if (jobType) {
    const jobTitle = jobTitleMap[jobType];
    const jobTagType = jobTagTypeMap[jobType];
    const jobTag = { value: 'jobType', text: jobTitle, type: jobTagType };
    tags.push(jobTag);
  }

  if (users?.length) {
    tags.push(...users.map((user) => ({ ...user, isArray: 'users' })));
  }

  if (rangeDate?.length) {
    const rangeText = getDateRange(rangeDate);
    const rangeTag = { value: 'rangeDate', text: rangeText };
    tags.push(rangeTag);
  }

  return tags;
};

export function playSoundNotification () {
  try {
    // soundEnabled
    if (JSON.parse(localStorage.getItem('soundNotificationsEnabled'))) {
      const audio = new Audio(sound);
      audio.play();
    }
  } catch (error) {
    console.error('An error occurred while playing sound notification:', error);
  }
}

export const validateFile = (file) => {
  const mathParams = {
    image: { max: { size: 10, msg: '10MB' } },
    other: { max: { size: 50, msg: '50MB' } },
  };
  const fileType = file && (fileTypeMap[file.type] || 'other');
  const params = mathParams[fileType];
  const maxSize = file.size > params.max.size * 1024 * 1024;

  // const minSize = file.size < sizes.min.size * 1024 * 1024;
  // const validFormat = fileTypeMap[fileType];
  // if (!validFormat) {
  //   return {
  //     code: 'not-valid-format',
  //     message: `Unfortunately, the ${fileType} was not added, make sure that the ${fileType} matches the format.`,
  //   };
  // }
  // if (minSize) {
  //   return {
  //     code: 'size-too-less',
  //     message: `File is smaller than ${sizes.min.msg}`,
  //   };
  // }

  if (maxSize) {
    return {
      code: 'size-too-large',
      message: `File is larger than ${params.max.msg}`,
    };
  }
  return null;
};
