import { useRef } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useMutation } from '@tanstack/react-query';
import { api } from 'services';
import { toast } from 'react-toastify';
import { convertFileSizeToMB } from 'utils';
import { fileTypeMap } from 'cnst';

import { Button, Loading } from '@carbon/react';
import { CloseLarge, Document } from '@carbon/icons-react';

const SendFilesModal = ({ files = [], path, onClose }) => {
  const containerRef = useRef();
  useOnClickOutside(containerRef, onClose);

  const { mutate, isPending } = useMutation({
    mutationFn: (values) => api.post({ path, values }),
    onSuccess: onClose,
    onError: (error) => toast.error(error),
  });

  function handleSendFiles () {
    const formData = new FormData();

    formData.append('type', 'document');
    files.map((file) => formData.append('img', file, file.path));
    mutate(formData);
  }

  return (
    <div className="py-80 h-screen flex justify-center items-center">
      <div
        ref={containerRef}
        className="relative max-w-320 w-full mx-16 select-none bg-th-layer-01"
      >
        <div className="flex justify-between items-center gap-4">
          <h3 className="pl-16 text-20">1 file</h3>
          <Button onClick={onClose} kind="ghost">
            <CloseLarge size={20} />
          </Button>
        </div>

        <div className="px-16 pb-48">
          {files.map((file) => <File file={file} key={file.name} />)}
        </div>

        <div className="flex">
          <Button kind="ghost" className="basis-1/2" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isPending}
            className="basis-1/2"
            onClick={handleSendFiles}
          >
            Send
            {isPending && <Loading small withOverlay={false} />}
          </Button>
        </div>
      </div>
    </div>
  );
};

const File = ({ file }) => {
  const fileType = fileTypeMap[file?.type];

  return (
    <div className="w-full flex items-center gap-8 pt-8">
      <div className="shrink-0 w-64 h-64 flex justify-center items-center rounded-8 overflow-hidden bg-th-border-subtle-01">
        {fileType === 'image' && <img src={URL.createObjectURL(file)} alt="" className="w-full h-full object-cover" />}
        {fileType !== 'image' && <Document size={20} />}
      </div>

      <div>
        <p className="mb-4 line-clamp-1 text-14 text-th-text-primary">
          {file.name}
        </p>
        <p className="text-12 text-th-text-secondary">
          ({convertFileSizeToMB(file?.size)} MB)
        </p>
      </div>
    </div>
  );
};

export default SendFilesModal;
