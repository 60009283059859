// export const API_URL = "http://192.168.1.53:5123/v1";
// export const API_WS_ROOM_HUB = "http://192.168.1.53:5123/room-hub";

// export const API_URL = "https://api.taskswaves.com/v1";
// export const API_WS_ROOM_HUB = "https://api.taskswaves.com/room-hub";

export const API_URL = "https://api-crm.safety42.com/v1";
export const API_WS_ROOM_HUB = "https://api-crm.safety42.com/room-hub";

export const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sept",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const roles = {
  developer: "developer",
  admin: "admin",
  manager: "manager",
  checker: "checker",
  worker: "worker",
};

export const taskColumnsByRoleMap = {
  worker: ["newTasks", "inProcessTasks", "readyForCheckTasks"],
  checker: ["readyForCheckTasks", "needCorrectionTasks", "checkedTasks"],
  manager: [
    "newTasks",
    "inProcessTasks",
    "readyForCheckTasks",
    "needCorrectionTasks",
    "checkedTasks",
  ],
  admin: [
    "newTasks",
    "inProcessTasks",
    "readyForCheckTasks",
    "needCorrectionTasks",
    "checkedTasks",
  ],
  developer: [
    "newTasks",
    "inProcessTasks",
    "readyForCheckTasks",
    "needCorrectionTasks",
    "checkedTasks",
  ],
};

export const taskOptionsByStatusAndRole = {
  new: {
    developer: [
      { value: "new", text: "New task" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "new", text: "New task" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "new", text: "New task" },
      { value: "archived", text: "Archived" },
    ],
    checker: null,
    worker: null,
  },
  inProcess: {
    developer: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    checker: null,
    worker: [{ value: "readyForCheck", text: "Ready for check" }],
  },
  readyForCheck: {
    developer: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    checker: [
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
    ],
    worker: null,
  },
  needCorrection: {
    developer: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    checker: [
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
    ],
    worker: null,
  },
  checked: {
    developer: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    checker: [
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
    ],
    worker: null,
  },
  archived: {
    developer: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    admin: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    manager: [
      { value: "inProcess", text: "In process" },
      { value: "readyForCheck", text: "Ready for check" },
      { value: "needCorrection", text: "Needs correction" },
      { value: "checked", text: "Checked" },
      { value: "archived", text: "Archived" },
    ],
    checker: null,
    worker: null,
  },
};
export const taskOptionByStatusMap = {
  new: { value: "new", text: "New task" },
  inProcess: { value: "inProcess", text: "In process" },
  readyForCheck: { value: "readyForCheck", text: "Ready for check" },
  needCorrection: { value: "needCorrection", text: "Needs correction" },
  checked: { value: "checked", text: "Checked" },
  archived: { value: "archived", text: "Archived" },
};

export const taskColNameByIdMap = {
  newTasks: "New Task",
  readyForCheckTasks: "Ready for check",
  inProcessTasks: "In process",
  needCorrectionTasks: "Needs Correction",
  checkedTasks: "Checked",
};

export const totalTasksByIdMap = {
  newTasks: "totalUnresolvedTasks",
  readyForCheckTasks: "totalReadyForCheckTasks",
  inProcessTasks: "totalInProcessTasks",
  needCorrectionTasks: "totalNeedCorrectionsTasks",
  checkedTasks: "totalCheckedTasks",
};

export const taskColIdByRoleMap = {
  developer: "newTasks",
  admin: "newTasks",
  manager: "newTasks",
  checker: "readyForCheckTasks",
  worker: "newTasks",
};

export const taskColIdByStatusMap = {
  new: "newTasks",
  inProcess: "inProcessTasks",
  readyForCheck: "readyForCheckTasks",
  needCorrection: "needCorrectionTasks",
  checked: "checkedTasks",
  // archived: "archivedTasks",
};
export const statusColByIdMap = {
  newTasks: "new",
  inProcessTasks: "inProcess",
  readyForCheckTasks: "readyForCheck",
  needCorrectionTasks: "needCorrection",
  checkedTasks: "checked",
};

// create, read, update, delete, manage
export const permissions = {
  "newTasksCol.read": [roles.worker, roles.manager, roles.admin, roles.developer],
  "inProcessTasksCol.read": [roles.worker, roles.manager, roles.admin, roles.developer],
  "readyForCheckTasksCol.read": [roles.worker, roles.manager, roles.admin, roles.developer],
  "needCorrectionTasksCol.read": [roles.checker],
  "checkedTasksCol.read": [roles.worker, roles.manager, roles.admin, roles.developer],
  "adminSettingNav.read": [roles.admin, roles.developer, roles.developer],
  "clientsNav.read": [roles.admin, roles.manager, roles.developer],
  "activedTab.read": [roles.admin, roles.manager, roles.developer],
  "createNewTask.read": [roles.admin, roles.manager, roles.worker, roles.developer],
};

export const avaStylesByRoleMap = {
  developer: "text-th-text-on-color bg-frostbite",
  admin: "text-th-text-on-color bg-frostbite",
  manager: "text-th-text-on-color bg-vermilion",
  checker: "text-th-text-on-color bg-green",
  worker: "text-black bg-yellow",
};

export const stylesByJobTypeMap = {
  newShift: "text-th-tag-color-teal bg-th-tag-background-teal",
  dutyTime: "text-th-tag-color-blue bg-th-tag-background-blue",
  newCycle: "text-th-tag-color-cyan bg-th-tag-background-cyan",
  sleeper: "text-th-tag-color-purple bg-th-tag-background-purple",
  emergency: "text-th-tag-color-magenta bg-th-tag-background-magenta",
  unknown: "text-th-text-primary bg-th-tag-background-cool-gray",
  break: "text-th-tag-color-warm-gray bg-th-tag-background-warm-gray",
  help: "text-th-tag-color-red bg-th-tag-background-red",
  correction: "text-th-text-inverse bg-th-background-inverse",
  violation: "text-th-text-inverse bg-th-background-inverse",
  setup: "text-th-tag-color-green bg-th-tag-background-green",
};
export const jobTagTypeMap = {
  newShift: "teal",
  dutyTime: "blue",
  newCycle: "cyan",
  sleeper: "purple",
  emergency: "magenta",
  unknown: "cool-gray",
  break: "warm-gray",
  help: "red",
  correction: "high-contrast",
  violation: "high-contrast",
  setup: "green",
};
export const jobTitleMap = {
  newShift: "New shift",
  dutyTime: "Duty time",
  newCycle: "New cycle",
  sleeper: "Sleeper",
  emergency: "Emergency",
  unknown: "Unknown",
  break: "Break",
  help: "Help",
  correction: "Correction",
  violation: "Violation",
  setup: "Setup",
};
export const jobTypeOptions = [
  { value: "newShift", text: "New shift" },
  { value: "dutyTime", text: "Duty time" },
  { value: "newCycle", text: "New cycle" },
  { value: "sleeper", text: "Sleeper" },
  { value: "emergency", text: "Emergency" },
  { value: "break", text: "Break" },
  { value: "help", text: "Help" },
  { value: "correction", text: "Correction" },
  { value: "violation", text: "Violation" },
  { value: "setup", text: "Setup" },
  { value: "unknown", text: "Unknown" },
];
export const jobTypeDefaultOption = { value: "", text: "Select task type" };

export const filterRoomDefaultValues = {
  drivers: [],
  jobType: "",
  users: [],
  rangeDate: [],
};
export const filterMCDefaultValues = {
  companyNames: [],
  status: "",
};
export const filterDriversDefaultValues = {
  companyNames: [],
  status: "",
};

export const checkListOptions = [
  { value: "originalLog", text: "Original LOG" },
  { value: "checkBol", text: "Check BOL" },
  { value: "checkFuel", text: "Check fuel" },
  { value: "screenProfile", text: "Profile form screen" },
  {
    value: "technicalCheck",
    text: "Check technical issues (Location, Odometer, Sleeper, Personal Use,Intermediate, Engine Power-up/Shut-down)",
  },
  {
    value: "doubleCheck",
    text: "Double check profile form (Pickup, Delivery, Fuel, Trailer)",
  },
  { value: "fortexScreenshoot", text: "Screenshot from Fortex" },
];

export const messageCardStyleMap = {
  customer: {
    card: "max-w-320 mr-auto px-12 py-8 text-th-text-primary bg-th-layer-accent-02 rounded-tr-16 rounded-tl-16 rounded-br-16",
    primaryColor: "",
    secondaryColor: "text-th-text-secondary",
    linkColor: "text-th-text-primary",
    fileIconColor: "fill-th-icon-primary",
    fileIconContainerColor: "bg-th-layer-02",
  },
  worker: {
    card: "max-w-320 ml-auto px-12 py-8 text-16 text-th-text-on-color bg-th-background-brand rounded-tr-16 rounded-tl-16 rounded-bl-16",
    primaryColor: "",
    secondaryColor: "text-th-text-on-color",
    linkColor: "text-th-text-on-color",
    fileIconColor: "fill-black",
    fileIconContainerColor: "bg-white",
  },
  system: {
    card: "max-w-320 mr-auto pl-10 after:content-[''] after:absolute after:top-0 after:left-0 after:h-full after:w-3 after:rounded-100 after:bg-th-border-interactive",
    primaryColor: "",
    secondaryColor: "text-th-text-primary",
    linkColor: "text-th-text-secondary",
  },
};

export const historyCardStyleMap = {
  log: {
    card: "mb-20 pl-10 after:content-[''] after:absolute after:top-0 after:left-0 after:h-full after:w-3 after:rounded-100 after:bg-th-border-strong-01",
    primaryColor: "",
    secondaryColor: "text-th-text-secondary",
    infoFlex: "justify-start",
  },
  text: {
    card: "mb-20 px-12 py-8 rounded-8 bg-th-layer-accent-02",
    primaryColor: "",
    secondaryColor: "text-th-text-secondary",
  },
  document: {
    card: "mb-20 px-12 py-8 rounded-8 bg-th-layer-accent-02",
    primaryColor: "",
    secondaryColor: "text-th-text-secondary",
    linkColor: "text-th-text-primary",
    fileIconColor: "fill-th-icon-primary",
    fileIconContainerColor: "bg-th-layer-02",
  },
};

export const statusColor = {
  active: "text-th-support-success",
  inactive: "text-th-support-error",
};

export const statusTitleMap = {
  active: "Active",
  inactive: "Inactive",
  new: "New"
}

export const initOrderForTaskCol = {
  newTasks: "asc",
  inProcessTasks: "asc",
  readyForCheckTasks: "desc",
  needCorrectionTasks: "asc",
  checkedTasks: "desc",
}

export const fileTypeMap = {
  'audio/ogg': 'audio',
  'audio/mpeg': 'audio',
  'image/jpeg': 'image',
  'image/jpg': 'image',
  'image/png': 'image',
  // "application/pdf": "file",
  // "text/plain": "file",
  // "application/doc": "file",
  // "application/docx": "file",
  // "application/xlsx": "file",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "file",
};