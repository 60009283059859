import { cn, convertFileSizeToMB } from 'utils';
import { usePopupStore, useShallow } from 'stores';

function MessageImage ({ data, styles }) {
  const handleOpen = usePopupStore(useShallow((store) => store.setOpen));
  const { file, link } = data || {};

  const { secondaryColor } = styles || {};

  const handleClick = () => {
    handleOpen({
      key: 'chatPreviewImage',
      props: {
        link,
        fileName: `${file?.fileName}.${file?.fileExtension}`,
      },
    });
  };

  return (
    <div className="flex gap-8">
      <div
        className="shrink-0 w-48 h-48 rounded-8 overflow-hidden cursor-pointer"
        onClick={handleClick}
      >
        <img src={link} alt="" className="w-full h-full object-cover" />
      </div>

      <div>
        <p className="flex flex-nowrap mb-4 line-clamp-1 text-14">
          <span className="line-clamp-1">{file?.fileName}</span>
          {file?.fileExtension && `.${file.fileExtension}`}
        </p>
        <p className={cn('text-12', secondaryColor)}>
          ({convertFileSizeToMB(file?.fileSize)} MB)
        </p>
      </div>
    </div>
  );
}

export default MessageImage;
